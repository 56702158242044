.social-message-input-tab,
.social-message-preview-tab {
  @extend .border-bottom;
  @extend .border-left;
  @extend .border-right;
  @extend .rounded-bottom;
}

.social-message-input-tab {
  @extend .p-2;
}

.social-message-preview-tab {
  @extend .p-2;
  @extend .pl-3;
  @extend .pr-3;
  min-height: 103px;
}
