$volume-meter-bg-color: #000000;
$volume-meter-thickness: 12px;
$volume-meter-text-size: 9px;
$volume-meter-text-color: #2dff34;

.volume-meter {
  display: flex;
  flex-direction: column;
  margin: 1px 0;

  .volume-meter-name {
    width: $volume-meter-thickness;
    height: $volume-meter-thickness;
    font-size: $volume-meter-text-size;
    line-height: $volume-meter-thickness;
    text-align: center;
    color: $volume-meter-text-color;
    background-color: $volume-meter-bg-color;
    margin-left: 1px;
  }

  &.vertical {
    margin: 0 1px;
    .volume-meter-name {
      margin-left: 0;
      margin-top: 1px;
    }
  }
}

.volume-meter-inner {
  position: relative;
  background: linear-gradient(90deg, rgba(0, 223, 40, 1) 0%, rgba(255, 255, 46, 1) 80%, rgba(255, 97, 0, 1) 100%);
  width: calc(100% - 2px); // 2px = margin + border
  height: $volume-meter-thickness;
  min-height: $volume-meter-thickness;
  border: 0.8px solid $volume-meter-bg-color;

  .gauge-mask {
    background-color: $volume-meter-bg-color;
    position: absolute;
    width: 0;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
  }
}

.volume-meter.reversed .volume-meter-inner {
  background: linear-gradient(270deg, rgba(0, 223, 40, 1) 0%, rgba(255, 255, 46, 1) 80%, rgba(255, 97, 0, 1) 100%);
  .gauge-mask {
    left: 0;
    right: auto;
  }
}
.volume-meter.vertical .volume-meter-inner {
  background: linear-gradient(0deg, rgba(0, 223, 40, 1) 0%, rgba(255, 255, 46, 1) 80%, rgba(255, 97, 0, 1) 100%);
  height: calc(100% - 2px); // 2px = margin + border
  width: $volume-meter-thickness;
  min-width: $volume-meter-thickness; //min-width because only with doesn't work on flex column
  .gauge-mask {
    left: 0;
    right: 0;
    height: 0;
    width: auto;
    top: 0;
    bottom: auto;
  }
}

.volume-meter.vertical.reversed .volume-meter-inner {
  background: linear-gradient(180deg, rgba(0, 223, 40, 1) 0%, rgba(255, 255, 46, 1) 80%, rgba(255, 97, 0, 1) 100%);
  .gauge-mask {
    top: auto;
    bottom: 0;
  }
}
