$cursor-color: #007bff;
$cursor-preview-color: #b4b4b4;

.cursor-none:hover {
  cursor: none;
}

.cursor-move:hover {
  cursor: move;
}

.unselectable-text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  min-height: 0;
}

.overflow-x-auto {
  min-width: 0;
}

.min-width-100 {
  min-width: 100%;
}

.z-index-4 {
  z-index: 10;
}

.timeline-body {
  border: 0;
}

.timeline-ruler {
  min-height: 25px;
  height: 25px;
  border-bottom: 1px solid #808080;
}

.timeline-ruler-tick {
  border-left: 1px solid #808080;
  height: 12px;
  margin-top: 13px;
}

.timeline-ruler-tick-ms {
  border-left: 1px solid #808080;
  height: 5px;
  margin-top: 20px;
}

.timeline-ruler-time {
  font-size: 10px;
  margin-top: 0px;
}

.timeline-ruler-cursor,
.timeline-ruler-cursor-preview {
  margin-top: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid;
}

.timeline-ruler-cursor {
  border-top-color: $cursor-color;
}

.timeline-ruler-cursor-preview {
  border-top-color: $cursor-preview-color;
}

.timeline-track {
  border-bottom: 1px solid #808080;
  min-height: 30px;
  height: 30px;
}
.timeline-track:hover {
  cursor: pointer;
}

.timeline-track-header-text {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  svg.timeline-track-header-icon {
    width: 30px !important;
    height: 20px !important;
  }
}

// timeline-track-section ///////////////////////////////
$timeline-section-grip-width: 6px;

.timeline-track-section {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
  bottom: 2px;
  height: 26px;
  z-index: 1;
  opacity: 0.8;
}

.timeline-track-section:hover {
  opacity: 1;
}

.timeline-track-section-inner {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
  text-align: center;

  &.movable {
    &:hover {
      cursor: move;
    }
  }
}

.timeline-track-section-selected .timeline-track-section-inner {
  border-color: rgb(30, 144, 255);
}

.timeline-track-section-grip-left,
.timeline-track-section-grip-right {
  position: absolute;
  padding: 0;
  margin: 0;
  border: 0;
  top: 0;
  bottom: 0;
  width: $timeline-section-grip-width;
  background-color: transparent !important;
  z-index: 10;
  &.resizable {
    &:hover {
      cursor: ew-resize;
    }
  }
}

.timeline-track-section-transition {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
  bottom: 2px;
  height: 26px;
  z-index: 15;

  .timeline-track-section-inner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    min-width: 18px;
  }
}

.timeline-track-section-transition-selected {
  opacity: 0.7;
  background-color: lightgreen !important;
  border: 2px solid lightgreen;
  border-color: rgb(30, 144, 255);

  .icon-transition {
    color: lightgreen;
  }
}

.icon-transition {
  background: black;
  padding: 2px;
  border: black 1px solid;
  border-radius: 2px;
  cursor: pointer;
  transition: height 0.3s;
}

.icon-transition-on-drag {
  background: blue;
  height: 90%;
}

.timeline-track-section-grip-left {
  left: -($timeline-section-grip-width / 2);
}

.timeline-track-section-grip-right {
  right: -($timeline-section-grip-width / 2);
}

.timeline-track-marker {
  border-left: 1px solid #808080;
}

.timeline-track-cursor-preview {
  border-left: 2px solid $cursor-preview-color;
  z-index: 10;
}

.timeline-track-cursor {
  border-left: 2px solid $cursor-color;
  z-index: 10;
}

.timeline-ruler-border-top {
  border-top: 1px solid #808080;
}

.timeline-current-track {
  background-color: #e0e0e0;
  cursor: default;
}

.timeline-zoom-display {
  width: 50px;
}

.timeline-unselected-track {
  background-color: #ffffff;
}

.timeline-diamond-event-hidden {
  padding: 5px;
  opacity: 0;
  z-index: 3;
}

.timeline-header-color-transparent {
  color: transparent;
}

.timeline-ruler-min-height {
  min-height: 25px;
}

.timeline-container-headers-scrollbar::-webkit-scrollbar {
  width: 0 !important;
}

.timeline-container-headers-scrollbar {
  -ms-overflow-style: none;
}

.timeline-ruler-border-bottom {
  border-bottom: 1px solid #808080;
}

.timeline-header-separator {
  left: 0px;
  position: sticky !important;
}

.marker-up {
  margin-top: 10px;
  height: 10px;
  width: 12px;
  background-color: #0088d6;
  :hover > & {
    cursor: pointer;
    background-color: #808080 !important;
  }
}

.marker-down {
  top: 20px;
  border-width: 5px 6px 0 6px;
  border-style: solid;
  border-color: $cursor-color transparent transparent transparent;
  :hover > & {
    cursor: pointer;
    border-top-color: #808080 !important;
  }
}

.update-duration-split > .dropdown-menu {
  transform: translate3d(0px, 33px, 0px) !important;
  min-width: 0 !important;
}

.update-playback-rate-dropdown .dropdown-menu {
  min-width: 0 !important;
  width: auto;
}

.text-black {
  color: black;
}
