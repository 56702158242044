.vp-control-bar {
  .zoom-slider {
    height: 100px;
    width: 30px;
    padding: 0 !important;
    margin: 0 0 0 20px !important;
    input[type='range'] {
      width: 90px !important;
      transform-origin: center left;
      transform: rotate(90deg);
    }
  }
}
.tb-switch {
  .custom-control-label::before {
    background-color: transparent;
  }
}
