.resource-gallery-modal {
  .resource-gallery-bg {
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .resource-gallery-img-body {
    top: 0;
    left: 0;
    z-index: 10001;
  }

  .resource-gallery-img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 30px;
  }

  .resource-gallery-video {
    object-fit: contain;
  }

  .resource-gallery-controls {
    top: 0;
    left: 0;
    z-index: 10002;
  }

  .resource-gallery-controls,
  .resource-gallery-btn {
    color: #fff;
  }
}
