$thumb-color: #aaaaaa;
$thumb-border-width: 1px;
$thumb-shadow-size: 0;
$thumb-radius: 6px;
$thumb-height: 12px;
$thumb-width: 12px;
$track-color: #aaaaaa;
$track-border-width: 0;
$track-shadow-size: 0;
$track-height: 2px;

@import '../../../node_modules/input-range-scss';
