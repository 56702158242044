@import '../node_modules/bootswatch/dist/cyborg/_variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootswatch/dist/cyborg/_bootswatch.scss';
@import '../node_modules/@eolementhe/react-components/dist/dependencies.scss';
@import '../node_modules/@eolementhe/react-components/dist/index.scss';

@import 'styles/themes';
@import 'styles/bootswatch-overrides';

// General css
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Make react-bootstrap tabs actually hidden when not selected. */
/* https://stackoverflow.com/questions/23772673/ */
.tab-pane[aria-hidden='true'] {
  display: none !important;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.display-text {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
