/* Fadeblack in/out animation */
@keyframes kf-fadeblack-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.anim-fadeblack-out {
  opacity: 1;
  animation-name: kf-fadeblack-out;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes kf-fadeblack-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.anim-fadeblack-in {
  opacity: 1;
  animation-name: kf-fadeblack-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
/* Fade in/out animation */
@keyframes kf-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.anim-fade-out {
  opacity: 0;
  animation-name: kf-fade-out;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes kf-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.anim-fade-in {
  opacity: 1;
  animation-name: kf-fade-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
/* Wipeleft in/out animation */
@keyframes kf-wipeleft-in {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.anim-wipeleft-in {
  animation-name: kf-wipeleft-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes kf-wipeleft-out {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 100%);
  }
}
.anim-wipeleft-out {
  animation-name: kf-wipeleft-out;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
/* Wiperight in/out animation */
@keyframes kf-wiperight-in {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.anim-wiperight-in {
  animation-name: kf-wiperight-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes kf-wiperight-out {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 100% 0 0);
  }
}
.anim-wiperight-out {
  animation-name: kf-wiperight-out;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
/* Wipedown in/out animation */
@keyframes kf-wipedown-in {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.anim-wipedown-in {
  animation-name: kf-wipedown-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes kf-wipedown-out {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 100% 0);
  }
}
.anim-wipedown-out {
  animation-name: kf-wipedown-out;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
/* Wipeup in/out animation */
@keyframes kf-wipeup-in {
  from {
    clip-path: inset(100% 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.anim-wipeup-in {
  animation-name: kf-wipeup-in;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}

@keyframes kf-wipeup-out {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(100% 0 0 0);
  }
}
.anim-wipeup-out {
  animation-name: kf-wipeup-out;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
