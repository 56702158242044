.tutorial-wizard-bubble {
  background-color: #56bbdc;
  border-color: #56bbdc;
  padding: 15px;
  z-index: 1030; //between navbar and organization popup
  max-width: 800px;
}

.tutorial-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 20;
}

.tutorial-title {
  font-weight: 300;
}

.tutorial-text {
  font-weight: 300;
  text-align: justify;
}

.tutorial-close:hover {
  cursor: pointer;
  color: #91d4eb;
}

.tutorial-pagination {
  font-size: 14px;
}

.tutorial-next-button {
  color: #56bbdc;
}

.tutorial-element-disabled {
  pointer-events: none;
}

.tutorial-element-highlight {
  box-shadow: 0 0 10px #f8ffcf;
  border: 1px solid #f8ffcf;
  z-index: 50;
  position: relative;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 20px;
  height: 20px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -10px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -10px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -10px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -10px;
}
