@import '../../styles/themes';
@import 'RangeInput';

$volume-meter-background-color: $border-color !important;

.konvajs-content,
.konvajs-content > canvas {
  width: 100% !important;
  height: 100% !important;
}

.video-editor {
  min-height: 0;
  background-color: #181818;
}

.video-editor-top {
  min-height: 0;
  border-color: transparent !important;
}
.video-editor-top .d-flex {
  border-color: transparent !important;
}

.video-editor-bottom {
  min-height: 160px;
  margin-left: 38px;
  width: calc(100% - 38px);
}

.video-editor-area-container {
  min-width: 0;
}

.video-editor-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.border-wrapper {
  border: 3px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.border-wrapper:hover {
  border-color: $color-primary;
}

.video-editor-right-panel {
  min-width: 320px;
  width: 320px;
  display: none !important;
}

.video-editor-properties {
  overflow-y: auto;
  min-height: 0;
}

.video-editor-right-panel .nav-tabs .nav-link,
.video-editor-right-panel .nav-pills .nav-link,
.video-editor-right-panel .nav-tabs .nav-link.active,
.video-editor-right-panel .nav-tabs .nav-item.show .nav-link {
  color: $text-color;
}

.video-editor-right-panel .nav-tabs .nav-link.active,
.video-editor-right-panel .nav-pills .nav-link.active {
  color: $color-primary;
  border-color: $color-primary;
  border-bottom: 0px;
  background: transparent;
}

.video-editor-area-container #ZoomToolbarContainer {
  overflow: hidden;
}
.video-editor-area-container #ZoomToolbarContainer .input-group {
  flex-direction: column;
  display: block;
  width: 35px;
  margin-left: 0px;
  margin-top: 10px;
}

.video-editor-area-container .btn-group button {
  width: 35px;
  height: 35px;
  font-size: 1.1rem;
}

.video-editor-area-container #ZoomToolbarContainer .input-group .btn-group {
  flex-direction: column;
  width: 35px;
  margin-left: 0px;
}

.disabled-url-fetching {
  filter: grayscale(1);
  display: none;
}
.tracklist-resizer-wrapper {
  width: 100%;
  background: transparent;
  height: 25px;
  display: flex;
  align-items: flex-end;
}
.tracklist-resizer {
  width: 100%;
  height: 7px;
  background: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  transition: 0.4s;
}
.tracklist-resizer:hover,
.tracklist-resizer:active {
  cursor: ns-resize;
  height: 15px;
}

.tracklist-resizer-icon {
  transform: scale(2, 0.2);
  transition: 0.4s;
}
.tracklist-resizer:hover .tracklist-resizer-icon,
.tracklist-resizer:active .tracklist-resizer-icon {
  transform: scale(2, 1);
}
//left bottom toolbar (zoom + screen size)
.video-editor-area-container .p-1 .input-group {
  width: 30px;
}
.video-editor-area-container .input-group .btn-group {
  flex-direction: column;
}
.video-editor-area-container .input-group .btn-group .timeline-zoom-display {
  width: 35px;
  font-size: 0.7rem;
  border-right: 1px solid #272727;
  border-left: 1px solid #272727;
}
.resource-browser-column-split {
  display: none;
}

.highlighted {
  color: #2a9fd6;
  background: #292929;
}

.timeline-container {
  .dropdown-menu {
    width: fit-content;
  }
}
