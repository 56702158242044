.core-chips-container {
  @extend .btn-group;
  @extend .d-flex;
}

.core-chips-input-container {
  @extend .d-flex;
  @extend .flex-row;
  @extend .border;
  @extend .rounded;
  @extend .flex-wrap;
  @extend .w-100;
  @extend .bg-white;
}

.core-chips-input {
  @extend .border-0;
  @extend .flex-fill;
  @extend .m-2;
}

.core-chips-input:focus {
  outline: none;
}

.core-chips-input::placeholder {
  @extend .font-italic;
}

.core-chips-tag {
  @extend .badge;
  @extend .badge-pill;
  @extend .badge-secondary;
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .m-2;
  @extend .ml-0;
}

.core-chips-tag-icon {
  @extend .ml-2;
}

.core-chips-tag-icon:hover {
  @extend .text-light;
  cursor: pointer;
}

.core-chips-input-panel {
  @extend .dropdown-menu;
  @extend .w-100;
  @extend .p-1;
}

.core-chips-input-list {
  max-height: 200px;
  overflow: auto;
}

.core-chips-input-item-active {
  @extend .bg-primary;
  @extend .text-white;
}

.core-chips-input-list-item {
  @extend .dropdown-menu;
  @extend .w-100;
  @extend .p-1;
}
