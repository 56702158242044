.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  width: 35px;
  height: 35px;
}

.ab-children {
  background-color: #292929;
  border: 1px solid #616161;
  z-index: 10001;
}
.ab-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ab-child-title {
  margin-left: 8px;
  white-space: nowrap;
}

.ab-active {
  color: #fff;
  border-color: #282828;
  background-color: #282828;
}

.ab-dropdown {
  position: relative;
  align-self: center;
  .ab-dropdown-content {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-10%, 102%);
    border: 1px solid #616161;
    background-color: #2e2e2e;
    border-radius: 4px;
    z-index: 10;
  }
}
