.idea-comment {
  @extend .mt-5;
  @extend .d-flex;
  @extend .flex-column;
}

.idea-comment-header {
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  font-size: 12px;
}

.idea-comment-username {
  @extend .font-weight-bold;
}

.idea-comment-created,
.idea-comment-report {
  @extend .ml-2;
  color: $gray-600;
}

.idea-comment-message {
  @extend .mt-2;
}
