.edit-section {
  color: white;
  height: 100%;

  .es-field {
    padding: 8px 0;

    &.es-field-small input {
      width: 60px;
    }

    label {
      margin-bottom: 4px;
    }
  }
  .es-header {
    .es-header-title {
      font-size: 25px;
      text-transform: capitalize;
    }
  }
  .es-body {
    padding: 5%;
    overflow: auto;
    height: 90%;

    .text-editor {
      background: transparent;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 5px;
      color: rgba(255, 255, 255, 0.8);
      width: 100%;
      padding: 4px 8px;
    }

    // New style
    .es-title {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
      margin: 0px;
      margin-bottom: 5px;
    }

    .es-input {
      font-size: 16px;
      min-height: 44px;
      vertical-align: middle;
      background: transparent;
      border: 2px solid #ffffff66;
      color: #ffffffcc;
      border-radius: 4px;
      width: 100%;
      resize: none;

      .es-option {
        color: #000;
        background-color: #eeeeee;
        font-size: 14px;
      }
    }

    .es-style-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      justify-content: space-between;
      width: 80px;
      height: 44px;
      padding: 0 4px;
      background: transparent;
      border: 2px solid #ffffff66;
      border-radius: 4px;

      .ess-icon {
        display: inline-block;
        padding: 0.3rem 0.5rem;
        cursor: pointer;
      }
    }

    .es-color {
      width: 44px;
      height: 44px;
      align-self: center;
      border-radius: 4px;
      background: transparent;
      border: 2px solid #ffffff66;
      padding: 4px;
      cursor: pointer;
    }

    .text-extras {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .es-color {
        margin-bottom: 6px;
      }
      .es-input {
        width: 44px;
      }
    }

    .es-wrapper {
      display: flex;
      flex-direction: row;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
        & > *:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .es-column {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-bottom: 20px;
    }

    .es-opacity-input {
      min-height: 0;
      height: 25px;
    }

    .opacity-wrapper {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      .opacity-range {
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
      }
      .es-input {
        width: 60px;
        text-align: center;
      }
    }
    .rotation-wrapper {
      .es-input {
        width: 60px;
        text-align: center;
      }
      & > *:not(:last-child) {
        margin-right: 6px;
      }
    }
    .value-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .es-input {
        width: 80px;
        text-align: center;
        margin-right: 6px;
      }
    }
    .es-value-editor {
      width: 200px;
    }
    .waveform-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .es-input {
        text-align: center;
        margin-right: 6px;
      }
    }
    .es-preset-btn {
      background: #ffffff0c;
      border: 2px solid #ffffff66;
      color: #ffffffcc;
      padding: 6px 8px;
      font-size: 16px;
      border-radius: 4px;
      outline: none;
      min-height: 44px;
      min-width: 44px;
    }
  }
}
