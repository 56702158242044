.content {
  z-index: 0;
}

#Homepage button {
  min-width: 380px;
  margin: 40px;
  margin-top: 80px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 25px;
  font-size: 2rem;
  transition: 0.1s;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.6);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);

  &:hover {
    background: rgba(255, 255, 255, 0.4);
    transform: scale3d(1.1, 1.1, 1);
  }

  &:active {
    transform: scale3d(1.05, 1.05, 1);
  }

  &:focus {
    outline: none !important;
  }

  svg {
    margin-right: 10px;
  }
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.btn-container {
  display: flex;
  justify-content: center;
}

//--------- FOR EVENTUAL INFO BUTTON ----------//

.info-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  padding: 10px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: 0.3s;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);

  svg {
    margin: 0;
  }

  span {
    opacity: 0;
    font-size: 0;
  }
}

.extended {
  width: 35vh;
  height: 35vh;
  border-radius: 5px;

  span {
    font-size: 1rem;
    opacity: 1;
    transition: 0.29s;
  }

  svg {
    opacity: 0;
  }
}
