.save-as-dropdown {
  display: flex;
  width: 460px;
  justify-content: center;
  padding: 22px 0;
  align-items: center;

  .sa-text {
    margin-right: 4px;
    color: #fffffff1;
  }
  .sa-input {
    font-size: 12px;
    vertical-align: middle;
    background: transparent;
    border: 1px solid #ffffff66;
    color: #fffffff1;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 14px;
    resize: none;
    min-width: 240px;
    outline: none;
  }
  .sa-button {
    font-size: 12px;
    outline: none;
    text-align: center;
    width: 54px;
    padding: 4px;
    border-radius: 5px;
    color: #fffffff1;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff66;
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}
