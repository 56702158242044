.export-options-menu {
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  .export-option {
    display: flex;
    align-items: baseline;
    transition: 0.2s;

    .option-link {
      white-space: nowrap;
    }
  }

  .export-option:hover {
    background: rgba(255, 255, 255, 0.3);
  }
}
