.core-checkable-select {
  @extend .btn-group;
  @extend .d-flex;
}

.core-checkable-select-button {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  @extend .btn;
  @extend .btn-light;
  @extend .btn-block;
}

.core-checkable-select-panel {
  @extend .dropdown-menu;
  @extend .p-1;
  min-width: 100%;
}

.core-checkable-select-search {
  @extend .form-control;
}

.core-checkable-select-list {
  @extend .list-group;
  @extend .mt-2;
  max-height: 200px;
  overflow: auto;
}

.core-checkable-select-list-item {
  @extend .form-check;
  @extend .mb-1;
  padding-left: 0;
}

.core-checkable-select-list-checkbox {
  @extend .form-check-input;
  cursor: pointer;
}

.core-checkable-select-list-label {
  cursor: pointer;
}
