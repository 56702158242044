.modal-close-button {
  position: absolute;
  font-size: 1rem;
  z-index: 1;
  top: 5px;
  right: 5px;
  opacity: 0.6;
  transition: 0.1s;
}

.add-tracks-modal {
  background: #292929;
  position: relative;
  border-radius: 0 4px 4px 4px;
  padding: 4px;
  min-width: 500px;
  max-width: 500px;
  max-height: 99%;
  margin-right: 20px;
  margin-top: 4px;
  margin-left: -8px;

  input {
    font-size: 1rem;
  }
}

.add-tracks-modal .add-tracks-modal-body {
  height: 100%;
  width: 480px;
}

.modal-close-button:hover {
  opacity: 1;
  cursor: pointer;
}

.add-tracks-modal .nav-item .disabled {
  color: white;
  margin-top: 5px;
  transition: 0.1s;
}

// Fixes for unknown bug
.add-tracks-modal-body .active {
  background: transparent;
}

// fixes for buttons dropbox
.add-tracks-modal .nav-item:hover .disabled {
  background: rgba(255, 255, 255, 0.2);
}
.add-tracks-modal .nav-item .disabled span {
  margin-top: 6px;
  width: 100% !important;
  max-width: none;
}
