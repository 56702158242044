.social-md-textarea {
  @extend .position-relative;
}

.social-md-textarea-textarea {
  @extend .form-control;
}

.social-md-textarea-emoji-suggestions {
  @extend .position-absolute;
  @extend .list-group;
  z-index: 1;
}

.social-md-textarea-emoji-suggestions-item {
  @extend .list-group-item;
  @extend .p-1;
}
