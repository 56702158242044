.new-project-modal > .modal-dialog {
  min-width: 500px;
}

.res-page-return-button {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.1s;
}
.res-page-return-button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9);
}

.delete-project-structure-icon {
  cursor: pointer;
}
