@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2A9FD6;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #CC0000;
  --orange: #fd7e14;
  --yellow: #FF8800;
  --green: #77B300;
  --teal: #20c997;
  --cyan: #9933CC;
  --white: #fff;
  --gray: #555;
  --gray-dark: #222;
  --primary: #2A9FD6;
  --secondary: #555;
  --success: #77B300;
  --info: #9933CC;
  --warning: #FF8800;
  --danger: #CC0000;
  --light: #222;
  --dark: #ADAFAE;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ADAFAE;
  text-align: left;
  background-color: #060606; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2A9FD6;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1d7097;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #555;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff; }

h1, .h1 {
  font-size: 4rem; }

h2, .h2 {
  font-size: 3rem; }

h3, .h3 {
  font-size: 2.5rem; }

h4, .h4 {
  font-size: 2rem; }

h5, .h5 {
  font-size: 1.5rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #555; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #060606;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #555; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: inherit; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #fff; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #282828; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #282828; }
  .table tbody + tbody {
    border-top: 2px solid #282828; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #282828; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #282828; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c3e4f4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #90cdea; }

.table-hover .table-primary:hover {
  background-color: #addaf0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #addaf0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cfcfcf; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a7a7a7; }

.table-hover .table-secondary:hover {
  background-color: #c2c2c2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c2c2c2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d9eab8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b8d77a; }

.table-hover .table-success:hover {
  background-color: #cee4a4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #cee4a4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e2c6f1; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #ca95e4; }

.table-hover .table-info:hover {
  background-color: #d8b2ec; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d8b2ec; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffdeb8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffc17a; }

.table-hover .table-warning:hover {
  background-color: #ffd29f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffd29f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1b8b8; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e47a7a; }

.table-hover .table-danger:hover {
  background-color: #eda3a3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eda3a3; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #c1c1c1; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #8c8c8c; }

.table-hover .table-light:hover {
  background-color: #b4b4b4; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #b4b4b4; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #e8e9e8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #d4d5d5; }

.table-hover .table-dark:hover {
  background-color: #dbdddb; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #dbdddb; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(255, 255, 255, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(242, 242, 242, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(242, 242, 242, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #888;
  border-color: #757575; }

.table .thead-light th {
  color: #282828;
  background-color: #e9ecef;
  border-color: #282828; }

.table-dark {
  color: #fff;
  background-color: #888; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #757575; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .core-typehead-input, .core-checkable-select-search, .social-md-textarea-textarea {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #282828;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .core-typehead-input, .core-checkable-select-search, .social-md-textarea-textarea {
      transition: none; } }
  .form-control::-ms-expand, .core-typehead-input::-ms-expand, .core-checkable-select-search::-ms-expand, .social-md-textarea-textarea::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .core-typehead-input:-moz-focusring, .core-checkable-select-search:-moz-focusring, .social-md-textarea-textarea:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #282828; }
  .form-control:focus, .core-typehead-input:focus, .core-checkable-select-search:focus, .social-md-textarea-textarea:focus {
    color: #282828;
    background-color: #fff;
    border-color: #95cfeb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .form-control::-webkit-input-placeholder, .core-typehead-input::-webkit-input-placeholder, .core-checkable-select-search::-webkit-input-placeholder, .social-md-textarea-textarea::-webkit-input-placeholder {
    color: #555;
    opacity: 1; }
  .form-control::placeholder, .core-typehead-input::placeholder, .core-checkable-select-search::placeholder, .social-md-textarea-textarea::placeholder {
    color: #555;
    opacity: 1; }
  .form-control:disabled, .core-typehead-input:disabled, .core-checkable-select-search:disabled, .social-md-textarea-textarea:disabled, .form-control[readonly], .core-typehead-input[readonly], .core-checkable-select-search[readonly], .social-md-textarea-textarea[readonly] {
    background-color: #ADAFAE;
    opacity: 1; }

select.form-control:focus::-ms-value, select.core-typehead-input:focus::-ms-value, select.core-checkable-select-search:focus::-ms-value, select.social-md-textarea-textarea:focus::-ms-value {
  color: #282828;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ADAFAE;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.core-typehead-input[size], select.core-checkable-select-search[size], select.social-md-textarea-textarea[size], select.form-control[multiple], select.core-typehead-input[multiple], select.core-checkable-select-search[multiple], select.social-md-textarea-textarea[multiple] {
  height: auto; }

textarea.form-control, textarea.core-typehead-input, textarea.core-checkable-select-search, textarea.social-md-textarea-textarea {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check, .core-checkable-select-list-item {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input, .core-checkable-select-list-checkbox {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label, .core-checkable-select-list-checkbox[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label,
  .core-checkable-select-list-checkbox:disabled ~ .form-check-label {
    color: #555; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input, .form-check-inline .core-checkable-select-list-checkbox {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #77B300; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: #77b300;
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .core-typehead-input:valid, .was-validated .core-checkable-select-search:valid, .was-validated .social-md-textarea-textarea:valid, .form-control.is-valid, .is-valid.core-typehead-input, .is-valid.core-checkable-select-search, .is-valid.social-md-textarea-textarea {
  border-color: #77B300;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377B300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .core-typehead-input:valid:focus, .was-validated .core-checkable-select-search:valid:focus, .was-validated .social-md-textarea-textarea:valid:focus, .form-control.is-valid:focus, .is-valid.core-typehead-input:focus, .is-valid.core-checkable-select-search:focus, .is-valid.social-md-textarea-textarea:focus {
    border-color: #77B300;
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.was-validated textarea.form-control:valid, .was-validated textarea.core-typehead-input:valid, .was-validated textarea.core-checkable-select-search:valid, .was-validated textarea.social-md-textarea-textarea:valid, textarea.form-control.is-valid, textarea.is-valid.core-typehead-input, textarea.is-valid.core-checkable-select-search, textarea.is-valid.social-md-textarea-textarea {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #77B300;
  padding-right: calc(0.75em + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377B300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #77B300;
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .was-validated .core-checkable-select-list-checkbox:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, .is-valid.core-checkable-select-list-checkbox ~ .form-check-label {
  color: #77B300; }

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .core-checkable-select-list-checkbox:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.was-validated .core-checkable-select-list-checkbox:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .is-valid.core-checkable-select-list-checkbox ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.is-valid.core-checkable-select-list-checkbox ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #77B300; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #77B300; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #99e600;
  background-color: #99e600; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #77B300; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #77B300; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #77B300;
  box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #CC0000; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: #cc0000;
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .core-typehead-input:invalid, .was-validated .core-checkable-select-search:invalid, .was-validated .social-md-textarea-textarea:invalid, .form-control.is-invalid, .is-invalid.core-typehead-input, .is-invalid.core-checkable-select-search, .is-invalid.social-md-textarea-textarea {
  border-color: #CC0000;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23CC0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23CC0000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .core-typehead-input:invalid:focus, .was-validated .core-checkable-select-search:invalid:focus, .was-validated .social-md-textarea-textarea:invalid:focus, .form-control.is-invalid:focus, .is-invalid.core-typehead-input:focus, .is-invalid.core-checkable-select-search:focus, .is-invalid.social-md-textarea-textarea:focus {
    border-color: #CC0000;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated textarea.form-control:invalid, .was-validated textarea.core-typehead-input:invalid, .was-validated textarea.core-checkable-select-search:invalid, .was-validated textarea.social-md-textarea-textarea:invalid, textarea.form-control.is-invalid, textarea.is-invalid.core-typehead-input, textarea.is-invalid.core-checkable-select-search, textarea.is-invalid.social-md-textarea-textarea {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #CC0000;
  padding-right: calc(0.75em + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23CC0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23CC0000' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #CC0000;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .was-validated .core-checkable-select-list-checkbox:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .is-invalid.core-checkable-select-list-checkbox ~ .form-check-label {
  color: #CC0000; }

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .core-checkable-select-list-checkbox:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.was-validated .core-checkable-select-list-checkbox:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .is-invalid.core-checkable-select-list-checkbox ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.is-invalid.core-checkable-select-list-checkbox ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #CC0000; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #CC0000; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: red;
  background-color: red; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #CC0000; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #CC0000; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #CC0000;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .form-inline .core-checkable-select-list-item {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .core-typehead-input, .form-inline .core-checkable-select-search, .form-inline .social-md-textarea-textarea {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check, .form-inline .core-checkable-select-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .form-inline .core-checkable-select-list-checkbox {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .core-checkable-select-button {
  display: inline-block;
  font-weight: 400;
  color: #ADAFAE;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .core-checkable-select-button {
      transition: none; } }
  .btn:hover, .core-checkable-select-button:hover {
    color: #ADAFAE;
    text-decoration: none; }
  .btn:focus, .core-checkable-select-button:focus, .btn.focus, .focus.core-checkable-select-button {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .btn.disabled, .disabled.core-checkable-select-button, .btn:disabled, .core-checkable-select-button:disabled {
    opacity: 0.65; }

a.btn.disabled, a.disabled.core-checkable-select-button,
fieldset:disabled a.btn,
fieldset:disabled a.core-checkable-select-button {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2A9FD6;
  border-color: #2A9FD6; }
  .btn-primary:hover {
    color: #fff;
    background-color: #2387b7;
    border-color: #2180ac; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #2387b7;
    border-color: #2180ac;
    box-shadow: 0 0 0 0.2rem rgba(74, 173, 220, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2180ac;
    border-color: #1f78a1; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 173, 220, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #555;
  border-color: #555; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c;
    box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #555;
    border-color: #555; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #353535; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }

.btn-success {
  color: #fff;
  background-color: #77B300;
  border-color: #77B300; }
  .btn-success:hover {
    color: #fff;
    background-color: #5e8d00;
    border-color: #558000; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #5e8d00;
    border-color: #558000;
    box-shadow: 0 0 0 0.2rem rgba(139, 190, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #77B300;
    border-color: #77B300; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #558000;
    border-color: #4d7300; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 190, 38, 0.5); }

.btn-info {
  color: #fff;
  background-color: #9933CC;
  border-color: #9933CC; }
  .btn-info:hover {
    color: #fff;
    background-color: #822bad;
    border-color: #7a29a3; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #822bad;
    border-color: #7a29a3;
    box-shadow: 0 0 0 0.2rem rgba(168, 82, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #9933CC;
    border-color: #9933CC; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #7a29a3;
    border-color: #732699; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 82, 212, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #FF8800;
  border-color: #FF8800; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d97400;
    border-color: #cc6d00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #d97400;
    border-color: #cc6d00;
    box-shadow: 0 0 0 0.2rem rgba(255, 154, 38, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #FF8800;
    border-color: #FF8800; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc6d00;
    border-color: #bf6600; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 154, 38, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #CC0000;
  border-color: #CC0000; }
  .btn-danger:hover {
    color: #fff;
    background-color: #a60000;
    border-color: #990000; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #a60000;
    border-color: #990000;
    box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #CC0000;
    border-color: #CC0000; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #990000;
    border-color: #8c0000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5); }

.btn-light, .core-checkable-select-button {
  color: #fff;
  background-color: #222;
  border-color: #222; }
  .btn-light:hover, .core-checkable-select-button:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909; }
  .btn-light:focus, .core-checkable-select-button:focus, .btn-light.focus, .focus.core-checkable-select-button {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909;
    box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }
  .btn-light.disabled, .disabled.core-checkable-select-button, .btn-light:disabled, .core-checkable-select-button:disabled {
    color: #fff;
    background-color: #222;
    border-color: #222; }
  .btn-light:not(:disabled):not(.disabled):active, .core-checkable-select-button:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .core-checkable-select-button:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle,
  .show > .dropdown-toggle.core-checkable-select-button {
    color: #fff;
    background-color: #090909;
    border-color: #020202; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .core-checkable-select-button:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .core-checkable-select-button:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus,
    .show > .dropdown-toggle.core-checkable-select-button:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #ADAFAE;
  border-color: #ADAFAE; }
  .btn-dark:hover {
    color: #fff;
    background-color: #9a9c9b;
    border-color: #939695; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #9a9c9b;
    border-color: #939695;
    box-shadow: 0 0 0 0.2rem rgba(185, 187, 186, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #ADAFAE;
    border-color: #ADAFAE; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #939695;
    border-color: #8d908e; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(185, 187, 186, 0.5); }

.btn-outline-primary {
  color: #2A9FD6;
  border-color: #2A9FD6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2A9FD6;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.5); }

.btn-outline-secondary {
  color: #555;
  border-color: #555; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #555;
    border-color: #555; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #555;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #555;
    border-color: #555; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.btn-outline-success {
  color: #77B300;
  border-color: #77B300; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #77B300;
    border-color: #77B300; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #77B300;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #77B300;
    border-color: #77B300; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.5); }

.btn-outline-info {
  color: #9933CC;
  border-color: #9933CC; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #9933CC;
    border-color: #9933CC; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 51, 204, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #9933CC;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #9933CC;
    border-color: #9933CC; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 51, 204, 0.5); }

.btn-outline-warning {
  color: #FF8800;
  border-color: #FF8800; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #FF8800;
    border-color: #FF8800; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FF8800;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #FF8800;
    border-color: #FF8800; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.5); }

.btn-outline-danger {
  color: #CC0000;
  border-color: #CC0000; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #CC0000;
    border-color: #CC0000; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #CC0000;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #CC0000;
    border-color: #CC0000; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }

.btn-outline-light {
  color: #222;
  border-color: #222; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #222;
    border-color: #222; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #222;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #222;
    border-color: #222; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.btn-outline-dark {
  color: #ADAFAE;
  border-color: #ADAFAE; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #ADAFAE;
    border-color: #ADAFAE; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 175, 174, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #ADAFAE;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #ADAFAE;
    border-color: #ADAFAE; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(173, 175, 174, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2A9FD6;
  text-decoration: none; }
  .btn-link:hover {
    color: #1d7097;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #555;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .core-checkable-select-button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .core-checkable-select-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block, .core-checkable-select-button {
  display: block;
  width: 100%; }
  .btn-block + .btn-block, .core-checkable-select-button + .btn-block, .btn-block + .core-checkable-select-button, .core-checkable-select-button + .core-checkable-select-button {
    margin-top: 0.5rem; }

input[type="submit"].btn-block, input.core-checkable-select-button[type="submit"],
input[type="reset"].btn-block,
input.core-checkable-select-button[type="reset"],
input[type="button"].btn-block,
input.core-checkable-select-button[type="button"] {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu, .core-chips-input-panel, .core-chips-input-list-item, .core-checkable-select-panel {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #ADAFAE;
  text-align: left;
  list-style: none;
  background-color: #282828;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu, .dropup .core-chips-input-panel, .dropup .core-chips-input-list-item, .dropup .core-checkable-select-panel {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu, .dropright .core-chips-input-panel, .dropright .core-chips-input-list-item, .dropright .core-checkable-select-panel {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu, .dropleft .core-chips-input-panel, .dropleft .core-chips-input-list-item, .dropleft .core-checkable-select-panel {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .core-chips-input-panel[x-placement^="top"], .core-chips-input-list-item[x-placement^="top"], .core-checkable-select-panel[x-placement^="top"], .dropdown-menu[x-placement^="right"], .core-chips-input-panel[x-placement^="right"], .core-chips-input-list-item[x-placement^="right"], .core-checkable-select-panel[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .core-chips-input-panel[x-placement^="bottom"], .core-chips-input-list-item[x-placement^="bottom"], .core-checkable-select-panel[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .core-chips-input-panel[x-placement^="left"], .core-chips-input-list-item[x-placement^="left"], .core-checkable-select-panel[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #222; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2A9FD6; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2A9FD6; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #555;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show, .show.core-chips-input-panel, .show.core-chips-input-list-item, .show.core-checkable-select-panel {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #555;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #fff; }

.btn-group, .core-chips-container, .core-checkable-select,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .core-chips-container > .btn, .core-checkable-select > .btn, .btn-group > .core-checkable-select-button, .core-chips-container > .core-checkable-select-button, .core-checkable-select > .core-checkable-select-button,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .core-checkable-select-button {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .core-chips-container > .btn:hover, .core-checkable-select > .btn:hover, .btn-group > .core-checkable-select-button:hover, .core-chips-container > .core-checkable-select-button:hover, .core-checkable-select > .core-checkable-select-button:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .core-checkable-select-button:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .core-chips-container > .btn:focus, .core-checkable-select > .btn:focus, .btn-group > .core-checkable-select-button:focus, .core-chips-container > .core-checkable-select-button:focus, .core-checkable-select > .core-checkable-select-button:focus, .btn-group > .btn:active, .core-chips-container > .btn:active, .core-checkable-select > .btn:active, .btn-group > .core-checkable-select-button:active, .core-chips-container > .core-checkable-select-button:active, .core-checkable-select > .core-checkable-select-button:active, .btn-group > .btn.active, .core-chips-container > .btn.active, .core-checkable-select > .btn.active, .btn-group > .active.core-checkable-select-button, .core-chips-container > .active.core-checkable-select-button, .core-checkable-select > .active.core-checkable-select-button,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .core-checkable-select-button:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .core-checkable-select-button:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.core-checkable-select-button {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .core-chips-container > .btn:not(:first-child), .core-checkable-select > .btn:not(:first-child), .btn-group > .core-checkable-select-button:not(:first-child), .core-chips-container > .core-checkable-select-button:not(:first-child), .core-checkable-select > .core-checkable-select-button:not(:first-child),
.btn-group > .btn-group:not(:first-child),
.core-chips-container > .btn-group:not(:first-child),
.core-checkable-select > .btn-group:not(:first-child),
.btn-group > .core-chips-container:not(:first-child),
.core-chips-container > .core-chips-container:not(:first-child),
.core-checkable-select > .core-chips-container:not(:first-child),
.btn-group > .core-checkable-select:not(:first-child),
.core-chips-container > .core-checkable-select:not(:first-child),
.core-checkable-select > .core-checkable-select:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .core-chips-container > .btn:not(:last-child):not(.dropdown-toggle), .core-checkable-select > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle), .core-chips-container > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle), .core-checkable-select > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.core-chips-container > .btn-group:not(:last-child) > .btn,
.core-checkable-select > .btn-group:not(:last-child) > .btn,
.btn-group > .core-chips-container:not(:last-child) > .btn,
.core-chips-container > .core-chips-container:not(:last-child) > .btn,
.core-checkable-select > .core-chips-container:not(:last-child) > .btn,
.btn-group > .core-checkable-select:not(:last-child) > .btn,
.core-chips-container > .core-checkable-select:not(:last-child) > .btn,
.core-checkable-select > .core-checkable-select:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .core-checkable-select-button,
.core-chips-container > .btn-group:not(:last-child) > .core-checkable-select-button,
.core-checkable-select > .btn-group:not(:last-child) > .core-checkable-select-button,
.btn-group > .core-chips-container:not(:last-child) > .core-checkable-select-button,
.core-chips-container > .core-chips-container:not(:last-child) > .core-checkable-select-button,
.core-checkable-select > .core-chips-container:not(:last-child) > .core-checkable-select-button,
.btn-group > .core-checkable-select:not(:last-child) > .core-checkable-select-button,
.core-chips-container > .core-checkable-select:not(:last-child) > .core-checkable-select-button,
.core-checkable-select > .core-checkable-select:not(:last-child) > .core-checkable-select-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .core-chips-container > .btn:not(:first-child), .core-checkable-select > .btn:not(:first-child), .btn-group > .core-checkable-select-button:not(:first-child), .core-chips-container > .core-checkable-select-button:not(:first-child), .core-checkable-select > .core-checkable-select-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.core-chips-container > .btn-group:not(:first-child) > .btn,
.core-checkable-select > .btn-group:not(:first-child) > .btn,
.btn-group > .core-chips-container:not(:first-child) > .btn,
.core-chips-container > .core-chips-container:not(:first-child) > .btn,
.core-checkable-select > .core-chips-container:not(:first-child) > .btn,
.btn-group > .core-checkable-select:not(:first-child) > .btn,
.core-chips-container > .core-checkable-select:not(:first-child) > .btn,
.core-checkable-select > .core-checkable-select:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .core-checkable-select-button,
.core-chips-container > .btn-group:not(:first-child) > .core-checkable-select-button,
.core-checkable-select > .btn-group:not(:first-child) > .core-checkable-select-button,
.btn-group > .core-chips-container:not(:first-child) > .core-checkable-select-button,
.core-chips-container > .core-chips-container:not(:first-child) > .core-checkable-select-button,
.core-checkable-select > .core-chips-container:not(:first-child) > .core-checkable-select-button,
.btn-group > .core-checkable-select:not(:first-child) > .core-checkable-select-button,
.core-chips-container > .core-checkable-select:not(:first-child) > .core-checkable-select-button,
.core-checkable-select > .core-checkable-select:not(:first-child) > .core-checkable-select-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .core-checkable-select-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .core-checkable-select-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .core-checkable-select-button,
  .btn-group-vertical > .btn-group,
  .btn-group-vertical > .core-chips-container,
  .btn-group-vertical > .core-checkable-select {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .core-checkable-select-button:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .core-chips-container:not(:first-child),
  .btn-group-vertical > .core-checkable-select:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .core-chips-container:not(:last-child) > .btn,
  .btn-group-vertical > .core-checkable-select:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-chips-container:not(:last-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-checkable-select:not(:last-child) > .core-checkable-select-button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .core-checkable-select-button:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .core-chips-container:not(:first-child) > .btn,
  .btn-group-vertical > .core-checkable-select:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-chips-container:not(:first-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-checkable-select:not(:first-child) > .core-checkable-select-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .core-checkable-select-button,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .core-chips-container > .btn,
.btn-group-toggle > .core-checkable-select > .btn,
.btn-group-toggle > .btn-group > .core-checkable-select-button,
.btn-group-toggle > .core-chips-container > .core-checkable-select-button,
.btn-group-toggle > .core-checkable-select > .core-checkable-select-button {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .core-checkable-select-button input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .core-chips-container > .btn input[type="radio"],
  .btn-group-toggle > .core-checkable-select > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .core-chips-container > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .core-checkable-select > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .core-chips-container > .btn input[type="checkbox"],
  .btn-group-toggle > .core-checkable-select > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .core-checkable-select-button input[type="checkbox"],
  .btn-group-toggle > .core-chips-container > .core-checkable-select-button input[type="checkbox"],
  .btn-group-toggle > .core-checkable-select > .core-checkable-select-button input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > .core-typehead-input, .input-group > .core-checkable-select-search, .input-group > .social-md-textarea-textarea,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .input-group > .core-typehead-input + .form-control, .input-group > .core-checkable-select-search + .form-control, .input-group > .social-md-textarea-textarea + .form-control, .input-group > .form-control + .core-typehead-input, .input-group > .core-typehead-input + .core-typehead-input, .input-group > .core-checkable-select-search + .core-typehead-input, .input-group > .social-md-textarea-textarea + .core-typehead-input, .input-group > .form-control + .core-checkable-select-search, .input-group > .core-typehead-input + .core-checkable-select-search, .input-group > .core-checkable-select-search + .core-checkable-select-search, .input-group > .social-md-textarea-textarea + .core-checkable-select-search, .input-group > .form-control + .social-md-textarea-textarea, .input-group > .core-typehead-input + .social-md-textarea-textarea, .input-group > .core-checkable-select-search + .social-md-textarea-textarea, .input-group > .social-md-textarea-textarea + .social-md-textarea-textarea,
    .input-group > .form-control + .custom-select,
    .input-group > .core-typehead-input + .custom-select,
    .input-group > .core-checkable-select-search + .custom-select,
    .input-group > .social-md-textarea-textarea + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .core-typehead-input + .custom-file,
    .input-group > .core-checkable-select-search + .custom-file,
    .input-group > .social-md-textarea-textarea + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .core-typehead-input,
    .input-group > .form-control-plaintext + .core-checkable-select-search,
    .input-group > .form-control-plaintext + .social-md-textarea-textarea,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .core-typehead-input,
    .input-group > .custom-select + .core-checkable-select-search,
    .input-group > .custom-select + .social-md-textarea-textarea,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .core-typehead-input,
    .input-group > .custom-file + .core-checkable-select-search,
    .input-group > .custom-file + .social-md-textarea-textarea,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .input-group > .core-typehead-input:focus, .input-group > .core-checkable-select-search:focus, .input-group > .social-md-textarea-textarea:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .input-group > .core-typehead-input:not(:last-child), .input-group > .core-checkable-select-search:not(:last-child), .input-group > .social-md-textarea-textarea:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .input-group > .core-typehead-input:not(:first-child), .input-group > .core-checkable-select-search:not(:first-child), .input-group > .social-md-textarea-textarea:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .core-checkable-select-button,
  .input-group-append .btn,
  .input-group-append .core-checkable-select-button {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .core-checkable-select-button:focus,
    .input-group-append .btn:focus,
    .input-group-append .core-checkable-select-button:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .core-checkable-select-button + .btn, .input-group-prepend .btn + .core-checkable-select-button, .input-group-prepend .core-checkable-select-button + .core-checkable-select-button,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .core-checkable-select-button + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .core-checkable-select-button,
  .input-group-append .btn + .btn,
  .input-group-append .core-checkable-select-button + .btn,
  .input-group-append .btn + .core-checkable-select-button,
  .input-group-append .core-checkable-select-button + .core-checkable-select-button,
  .input-group-append .btn + .input-group-text,
  .input-group-append .core-checkable-select-button + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .core-checkable-select-button {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #282828;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > .core-typehead-input:not(textarea), .input-group-lg > .core-checkable-select-search:not(textarea), .input-group-lg > .social-md-textarea-textarea:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .input-group-lg > .core-typehead-input, .input-group-lg > .core-checkable-select-search, .input-group-lg > .social-md-textarea-textarea,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .core-checkable-select-button,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .core-checkable-select-button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > .core-typehead-input:not(textarea), .input-group-sm > .core-checkable-select-search:not(textarea), .input-group-sm > .social-md-textarea-textarea:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .input-group-sm > .core-typehead-input, .input-group-sm > .core-checkable-select-search, .input-group-sm > .social-md-textarea-textarea,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .core-checkable-select-button,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .core-checkable-select-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .core-checkable-select-button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .core-checkable-select-button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .core-checkable-select-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .core-checkable-select-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .core-checkable-select-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2A9FD6;
    background-color: #2A9FD6; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #95cfeb; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c0e2f3;
    border-color: #c0e2f3; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #555; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #ADAFAE; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #888 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2A9FD6;
  background-color: #2A9FD6; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(42, 159, 214, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(42, 159, 214, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(42, 159, 214, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #888;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(42, 159, 214, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 2rem 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #282828;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #95cfeb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
    .custom-select:focus::-ms-value {
      color: #282828;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #555;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #282828; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76562rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #95cfeb;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #ADAFAE; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #fff;
  border: 1px solid #282828;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 1rem;
    line-height: 1.5;
    color: #fff;
    content: "Browse";
    background-color: #282828;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #060606, 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #060606, 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #060606, 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2A9FD6;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #c0e2f3; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2A9FD6;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #c0e2f3; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2A9FD6;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #c0e2f3; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #888; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #888; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #888; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #555;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #282828; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #282828; }
    .nav-tabs .nav-link.disabled {
      color: #555;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #282828;
    border-color: #282828; }
  .nav-tabs .dropdown-menu, .nav-tabs .core-chips-input-panel, .nav-tabs .core-chips-input-list-item, .nav-tabs .core-checkable-select-panel {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2A9FD6; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu, .navbar-nav .core-chips-input-panel, .navbar-nav .core-chips-input-list-item, .navbar-nav .core-checkable-select-panel {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu, .navbar-expand-sm .navbar-nav .core-chips-input-panel, .navbar-expand-sm .navbar-nav .core-chips-input-list-item, .navbar-expand-sm .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu, .navbar-expand-md .navbar-nav .core-chips-input-panel, .navbar-expand-md .navbar-nav .core-chips-input-list-item, .navbar-expand-md .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu, .navbar-expand-lg .navbar-nav .core-chips-input-panel, .navbar-expand-lg .navbar-nav .core-chips-input-list-item, .navbar-expand-lg .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu, .navbar-expand-xl .navbar-nav .core-chips-input-panel, .navbar-expand-xl .navbar-nav .core-chips-input-list-item, .navbar-expand-xl .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu, .navbar-expand .navbar-nav .core-chips-input-panel, .navbar-expand .navbar-nav .core-chips-input-list-item, .navbar-expand .navbar-nav .core-checkable-select-panel {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #282828;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child, .card > .core-checkable-select-list:first-child .list-group-item:first-child, .card > .social-md-textarea-emoji-suggestions:first-child .list-group-item:first-child, .card > .list-group:first-child .social-md-textarea-emoji-suggestions-item:first-child, .card > .core-checkable-select-list:first-child .social-md-textarea-emoji-suggestions-item:first-child, .card > .social-md-textarea-emoji-suggestions:first-child .social-md-textarea-emoji-suggestions-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child, .card > .core-checkable-select-list:last-child .list-group-item:last-child, .card > .social-md-textarea-emoji-suggestions:last-child .list-group-item:last-child, .card > .list-group:last-child .social-md-textarea-emoji-suggestions-item:last-child, .card > .core-checkable-select-list:last-child .social-md-textarea-emoji-suggestions-item:last-child, .card > .social-md-textarea-emoji-suggestions:last-child .social-md-textarea-emoji-suggestions-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child, .card-header + .core-checkable-select-list .list-group-item:first-child, .card-header + .social-md-textarea-emoji-suggestions .list-group-item:first-child, .card-header + .list-group .social-md-textarea-emoji-suggestions-item:first-child, .card-header + .core-checkable-select-list .social-md-textarea-emoji-suggestions-item:first-child, .card-header + .social-md-textarea-emoji-suggestions .social-md-textarea-emoji-suggestions-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #282828;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #555;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #555; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fff;
  background-color: #282828;
  border: 1px solid transparent; }
  .page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #2A9FD6;
    border-color: transparent; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2A9FD6;
  border-color: #2A9FD6; }

.page-item.disabled .page-link {
  color: #555;
  pointer-events: none;
  cursor: auto;
  background-color: #282828;
  border-color: transparent; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge, .core-chips-tag {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge, .core-chips-tag {
      transition: none; } }
  a.badge:hover, a.core-chips-tag:hover, a.badge:focus, a.core-chips-tag:focus {
    text-decoration: none; }
  .badge:empty, .core-chips-tag:empty {
    display: none; }

.btn .badge, .core-checkable-select-button .badge, .btn .core-chips-tag, .core-checkable-select-button .core-chips-tag {
  position: relative;
  top: -1px; }

.badge-pill, .core-chips-tag {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2A9FD6; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #2180ac; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.5); }

.badge-secondary, .core-chips-tag {
  color: #fff;
  background-color: #555; }
  a.badge-secondary:hover, a.core-chips-tag:hover, a.badge-secondary:focus, a.core-chips-tag:focus {
    color: #fff;
    background-color: #3c3c3c; }
  a.badge-secondary:focus, a.core-chips-tag:focus, a.badge-secondary.focus, a.focus.core-chips-tag {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.badge-success {
  color: #fff;
  background-color: #77B300; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #558000; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.5); }

.badge-info {
  color: #fff;
  background-color: #9933CC; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #7a29a3; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(153, 51, 204, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #FF8800; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #cc6d00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #CC0000; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #990000; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }

.badge-light {
  color: #fff;
  background-color: #222; }
  a.badge-light:hover, a.badge-light:focus {
    color: #fff;
    background-color: #090909; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #ADAFAE; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #939695; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 175, 174, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #282828;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #16536f;
  background-color: #d4ecf7;
  border-color: #c3e4f4; }
  .alert-primary hr {
    border-top-color: #addaf0; }
  .alert-primary .alert-link {
    color: #0e3344; }

.alert-secondary {
  color: #2c2c2c;
  background-color: #dddddd;
  border-color: #cfcfcf; }
  .alert-secondary hr {
    border-top-color: #c2c2c2; }
  .alert-secondary .alert-link {
    color: #131313; }

.alert-success {
  color: #3e5d00;
  background-color: #e4f0cc;
  border-color: #d9eab8; }
  .alert-success hr {
    border-top-color: #cee4a4; }
  .alert-success .alert-link {
    color: #1c2a00; }

.alert-info {
  color: #501b6a;
  background-color: #ebd6f5;
  border-color: #e2c6f1; }
  .alert-info hr {
    border-top-color: #d8b2ec; }
  .alert-info .alert-link {
    color: #311141; }

.alert-warning {
  color: #854700;
  background-color: #ffe7cc;
  border-color: #ffdeb8; }
  .alert-warning hr {
    border-top-color: #ffd29f; }
  .alert-warning .alert-link {
    color: #522c00; }

.alert-danger {
  color: #6a0000;
  background-color: #f5cccc;
  border-color: #f1b8b8; }
  .alert-danger hr {
    border-top-color: #eda3a3; }
  .alert-danger .alert-link {
    color: #370000; }

.alert-light {
  color: #121212;
  background-color: lightgray;
  border-color: #c1c1c1; }
  .alert-light hr {
    border-top-color: #b4b4b4; }
  .alert-light .alert-link {
    color: black; }

.alert-dark {
  color: #5a5b5a;
  background-color: #efefef;
  border-color: #e8e9e8; }
  .alert-dark hr {
    border-top-color: #dbdddb; }
  .alert-dark .alert-link {
    color: #414141; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #282828;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2A9FD6;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group, .core-checkable-select-list, .social-md-textarea-emoji-suggestions {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #282828;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #282828;
    text-decoration: none;
    background-color: #2A9FD6; }
  .list-group-item-action:active {
    color: #ADAFAE;
    background-color: #2A9FD6; }

.list-group-item, .social-md-textarea-emoji-suggestions-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #222;
  border: 1px solid #282828; }
  .list-group-item:first-child, .social-md-textarea-emoji-suggestions-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child, .social-md-textarea-emoji-suggestions-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .disabled.social-md-textarea-emoji-suggestions-item, .list-group-item:disabled, .social-md-textarea-emoji-suggestions-item:disabled {
    color: #555;
    pointer-events: none;
    background-color: #282828; }
  .list-group-item.active, .active.social-md-textarea-emoji-suggestions-item {
    z-index: 2;
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
  .list-group-item + .list-group-item, .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-item + .social-md-textarea-emoji-suggestions-item, .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active, .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child, .list-group-horizontal .social-md-textarea-emoji-suggestions-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child, .list-group-horizontal .social-md-textarea-emoji-suggestions-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active, .list-group-horizontal .active.social-md-textarea-emoji-suggestions-item {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active, .list-group-horizontal-sm .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-sm .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-sm .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active, .list-group-horizontal-md .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-md .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-md .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active, .list-group-horizontal-lg .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-lg .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-lg .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active, .list-group-horizontal-xl .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-xl .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-xl .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item, .list-group-flush .social-md-textarea-emoji-suggestions-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child, .list-group-flush .social-md-textarea-emoji-suggestions-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child, .list-group-flush:last-child .social-md-textarea-emoji-suggestions-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #16536f;
  background-color: #c3e4f4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #16536f;
    background-color: #addaf0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #16536f;
    border-color: #16536f; }

.list-group-item-secondary {
  color: #2c2c2c;
  background-color: #cfcfcf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2c2c2c;
    background-color: #c2c2c2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #2c2c2c; }

.list-group-item-success {
  color: #3e5d00;
  background-color: #d9eab8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #3e5d00;
    background-color: #cee4a4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #3e5d00;
    border-color: #3e5d00; }

.list-group-item-info {
  color: #501b6a;
  background-color: #e2c6f1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #501b6a;
    background-color: #d8b2ec; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #501b6a;
    border-color: #501b6a; }

.list-group-item-warning {
  color: #854700;
  background-color: #ffdeb8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #854700;
    background-color: #ffd29f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854700;
    border-color: #854700; }

.list-group-item-danger {
  color: #6a0000;
  background-color: #f1b8b8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6a0000;
    background-color: #eda3a3; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6a0000;
    border-color: #6a0000; }

.list-group-item-light {
  color: #121212;
  background-color: #c1c1c1; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #121212;
    background-color: #b4b4b4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #121212;
    border-color: #121212; }

.list-group-item-dark {
  color: #5a5b5a;
  background-color: #e8e9e8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #5a5b5a;
    background-color: #dbdddb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #5a5b5a;
    border-color: #5a5b5a; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #fff;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #555;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #222;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #282828;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #282828;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #282828; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #282828; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #282828; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #282828; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #282828;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #282828;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #282828; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #282828; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #282828; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #202020; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #282828; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  background-color: #202020;
  border-bottom: 1px solid #141414;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #ADAFAE; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary, .core-chips-input-item-active {
  background-color: #2A9FD6 !important; }

a.bg-primary:hover, a.core-chips-input-item-active:hover, a.bg-primary:focus, a.core-chips-input-item-active:focus,
button.bg-primary:hover,
button.core-chips-input-item-active:hover,
button.bg-primary:focus,
button.core-chips-input-item-active:focus {
  background-color: #2180ac !important; }

.bg-secondary {
  background-color: #555 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3c3c3c !important; }

.bg-success {
  background-color: #77B300 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #558000 !important; }

.bg-info {
  background-color: #9933CC !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7a29a3 !important; }

.bg-warning {
  background-color: #FF8800 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc6d00 !important; }

.bg-danger {
  background-color: #CC0000 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #990000 !important; }

.bg-light {
  background-color: #222 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #090909 !important; }

.bg-dark {
  background-color: #ADAFAE !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #939695 !important; }

.bg-white, .core-chips-input-container {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .core-chips-input-container {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right, .social-message-input-tab,
.social-message-preview-tab {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom, .social-message-input-tab,
.social-message-preview-tab {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left, .social-message-input-tab,
.social-message-preview-tab {
  border-left: 1px solid #dee2e6 !important; }

.border-0, .core-chips-input {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2A9FD6 !important; }

.border-secondary {
  border-color: #555 !important; }

.border-success {
  border-color: #77B300 !important; }

.border-info {
  border-color: #9933CC !important; }

.border-warning {
  border-color: #FF8800 !important; }

.border-danger, .tweet-input-message-warning {
  border-color: #CC0000 !important; }

.border-light {
  border-color: #222 !important; }

.border-dark {
  border-color: #ADAFAE !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded, .core-chips-input-container {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom, .social-message-input-tab,
.social-message-preview-tab {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .core-chips-container, .core-chips-input-container, .core-chips-tag, .core-checkable-select, .core-checkable-select-button, .authentication-form, .idea-comment, .idea-comment-header, .tweet-input {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row, .core-chips-input-container, .core-chips-tag, .idea-comment-header {
  flex-direction: row !important; }

.flex-column, .authentication-form, .idea-comment, .tweet-input {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap, .core-chips-input-container {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill, .core-chips-input {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between, .core-checkable-select-button {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .core-chips-tag, .core-checkable-select-button, .idea-comment-header {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto, .core-typehead-suggestions {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative, .core-typehead, .social-md-textarea {
  position: relative !important; }

.position-absolute, .core-typehead-suggestions, .social-md-textarea-emoji-suggestions {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100, .core-typehead-suggestions, .core-chips-input-container, .core-chips-input-panel, .core-chips-input-list-item {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0, .announcement-banner-message > p,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0, .core-chips-tag,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .core-checkable-select-list-item, .authentication-label,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2, .core-chips-input, .core-chips-tag {
  margin: 0.5rem !important; }

.mt-2, .core-checkable-select-list, .authentication-password-label, .authentication-submit-button, .authentication-disclaimer, .idea-comment-message,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .core-chips-tag-icon, .idea-comment-created,
.idea-comment-report,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .idea-comment,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1, .core-typehead-suggestions-item, .core-chips-input-panel, .core-chips-input-list-item, .core-checkable-select-panel, .authentication-form, .social-md-textarea-emoji-suggestions-item {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2, .social-message-input-tab,
.social-message-preview-tab {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.social-message-preview-tab,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.social-message-preview-tab,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right, .tweet-input-count {
  text-align: right !important; }

.text-center, .authentication-title {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light, .authentication-display-mode-label, .authentication-disclaimer {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold, .idea-comment-username {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.core-chips-input::-webkit-input-placeholder {
  font-style: italic !important; }

.font-italic, .core-chips-input::placeholder, .authentication-display-mode-label {
  font-style: italic !important; }

.text-white, .core-chips-input-item-active {
  color: #fff !important; }

.text-primary, .link-button {
  color: #2A9FD6 !important; }

a.text-primary:hover, a.link-button:hover, a.text-primary:focus, a.link-button:focus {
  color: #1d7097 !important; }

.text-secondary {
  color: #555 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #2f2f2f !important; }

.text-success {
  color: #77B300 !important; }

a.text-success:hover, a.text-success:focus {
  color: #446700 !important; }

.text-info {
  color: #9933CC !important; }

a.text-info:hover, a.text-info:focus {
  color: #6b248f !important; }

.text-warning {
  color: #FF8800 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b35f00 !important; }

.text-danger, .tweet-input-color-warning {
  color: #CC0000 !important; }

a.text-danger:hover, a.tweet-input-color-warning:hover, a.text-danger:focus, a.tweet-input-color-warning:focus {
  color: maroon !important; }

.text-light, .core-chips-tag-icon:hover {
  color: #222 !important; }

a.text-light:hover, a.core-chips-tag-icon:hover, a.text-light:focus, a.core-chips-tag-icon:focus:hover {
  color: black !important; }

.text-dark {
  color: #ADAFAE !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #868988 !important; }

.text-body {
  color: #ADAFAE !important; }

.text-muted, .tweet-input {
  color: #555 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.core-checkable-select-button) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #888;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge, .core-chips-tag {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #282828; }
  .table .thead-dark th {
    color: inherit;
    border-color: #282828; } }

.navbar.bg-primary, .navbar.core-chips-input-item-active {
  border: 1px solid #282828; }

.navbar.bg-dark {
  background-color: #060606 !important;
  border: 1px solid #282828; }

.navbar.bg-light {
  background-color: #888 !important; }

.navbar.fixed-top {
  border-width: 0 0 1px 0; }

.navbar.fixed-bottom {
  border-width: 1px 0 0 0; }

.btn-primary {
  background-color: #2A9FD6; }

.btn-secondary {
  background-color: #555; }

.btn-success {
  background-color: #77B300; }

.btn-info {
  background-color: #9933CC; }

.btn-warning {
  background-color: #FF8800; }

.btn-danger {
  background-color: #CC0000; }

.btn-light, .core-checkable-select-button {
  background-color: #222; }

.btn-dark {
  background-color: #ADAFAE; }

table {
  color: #fff; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #2A9FD6; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #555; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #222; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #ADAFAE; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #77B300; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #9933CC; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #CC0000; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #FF8800; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(255, 255, 255, 0.075); }

.table-hover .table-primary:hover, .table-hover .table-primary:hover > th, .table-hover .table-primary:hover > td {
  background-color: #258fc1; }

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > th, .table-hover .table-secondary:hover > td {
  background-color: #484848; }

.table-hover .table-light:hover, .table-hover .table-light:hover > th, .table-hover .table-light:hover > td {
  background-color: #151515; }

.table-hover .table-dark:hover, .table-hover .table-dark:hover > th, .table-hover .table-dark:hover > td {
  background-color: #a0a2a1; }

.table-hover .table-success:hover, .table-hover .table-success:hover > th, .table-hover .table-success:hover > td {
  background-color: #669a00; }

.table-hover .table-info:hover, .table-hover .table-info:hover > th, .table-hover .table-info:hover > td {
  background-color: #8a2eb8; }

.table-hover .table-danger:hover, .table-hover .table-danger:hover > th, .table-hover .table-danger:hover > td {
  background-color: #b30000; }

.table-hover .table-warning:hover, .table-hover .table-warning:hover > th, .table-hover .table-warning:hover > td {
  background-color: #e67a00; }

.table-hover .table-active:hover, .table-hover .table-active:hover > th, .table-hover .table-active:hover > td {
  background-color: rgba(255, 255, 255, 0.075); }

legend {
  color: #fff; }

.nav-tabs .nav-link,
.nav-pills .nav-link {
  color: #fff; }
  .nav-tabs .nav-link:hover,
  .nav-pills .nav-link:hover {
    background-color: #282828; }
  .nav-tabs .nav-link.disabled, .nav-tabs .nav-link.disabled:hover,
  .nav-pills .nav-link.disabled,
  .nav-pills .nav-link.disabled:hover {
    background-color: transparent;
    color: #555; }
  .nav-tabs .nav-link.active,
  .nav-pills .nav-link.active {
    background-color: #2A9FD6; }

.breadcrumb a {
  color: #fff; }

.pagination a:hover {
  text-decoration: none; }

.alert {
  border: none;
  color: #fff; }
  .alert a,
  .alert .alert-link {
    color: #fff;
    text-decoration: underline; }
  .alert-primary {
    background-color: #2A9FD6; }
  .alert-secondary {
    background-color: #555; }
  .alert-success {
    background-color: #77B300; }
  .alert-info {
    background-color: #9933CC; }
  .alert-warning {
    background-color: #FF8800; }
  .alert-danger {
    background-color: #CC0000; }
  .alert-light {
    background-color: #222; }
  .alert-dark {
    background-color: #ADAFAE; }

.badge-warning {
  color: #fff; }

.close {
  opacity: 0.6; }
  .close:hover {
    opacity: 1; }

.list-group-item:hover, .social-md-textarea-emoji-suggestions-item:hover {
  background-color: #282828;
  color: #fff; }

.list-group-item-action {
  color: #888; }
  .list-group-item-action .list-group-item-heading {
    color: #888; }

.list-group-item:hover .list-group-item-heading, .social-md-textarea-emoji-suggestions-item:hover .list-group-item-heading {
  color: #fff; }

.card h1, .card h2, .card h3, .card h4, .card h5, .card h6,
.list-group-item h1,
.social-md-textarea-emoji-suggestions-item h1,
.list-group-item h2,
.social-md-textarea-emoji-suggestions-item h2,
.list-group-item h3,
.social-md-textarea-emoji-suggestions-item h3,
.list-group-item h4,
.social-md-textarea-emoji-suggestions-item h4,
.list-group-item h5,
.social-md-textarea-emoji-suggestions-item h5,
.list-group-item h6,
.social-md-textarea-emoji-suggestions-item h6 {
  color: inherit; }

.popover-title {
  border-bottom: none; }

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2A9FD6;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #CC0000;
  --orange: #fd7e14;
  --yellow: #FF8800;
  --green: #77B300;
  --teal: #20c997;
  --cyan: #9933CC;
  --white: #fff;
  --gray: #555;
  --gray-dark: #222;
  --primary: #2A9FD6;
  --secondary: #555;
  --success: #77B300;
  --info: #9933CC;
  --warning: #FF8800;
  --danger: #CC0000;
  --light: #222;
  --dark: #ADAFAE;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ADAFAE;
  text-align: left;
  background-color: #060606; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2A9FD6;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1d7097;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #555;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff; }

h1, .h1 {
  font-size: 4rem; }

h2, .h2 {
  font-size: 3rem; }

h3, .h3 {
  font-size: 2.5rem; }

h4, .h4 {
  font-size: 2rem; }

h5, .h5 {
  font-size: 1.5rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #555; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #060606;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #555; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: inherit; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #fff; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #282828; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #282828; }
  .table tbody + tbody {
    border-top: 2px solid #282828; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #282828; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #282828; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c3e4f4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #90cdea; }

.table-hover .table-primary:hover {
  background-color: #addaf0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #addaf0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cfcfcf; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a7a7a7; }

.table-hover .table-secondary:hover {
  background-color: #c2c2c2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c2c2c2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d9eab8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b8d77a; }

.table-hover .table-success:hover {
  background-color: #cee4a4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #cee4a4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e2c6f1; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #ca95e4; }

.table-hover .table-info:hover {
  background-color: #d8b2ec; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d8b2ec; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffdeb8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffc17a; }

.table-hover .table-warning:hover {
  background-color: #ffd29f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffd29f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1b8b8; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e47a7a; }

.table-hover .table-danger:hover {
  background-color: #eda3a3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eda3a3; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #c1c1c1; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #8c8c8c; }

.table-hover .table-light:hover {
  background-color: #b4b4b4; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #b4b4b4; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #e8e9e8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #d4d5d5; }

.table-hover .table-dark:hover {
  background-color: #dbdddb; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #dbdddb; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(255, 255, 255, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(242, 242, 242, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(242, 242, 242, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #888;
  border-color: #757575; }

.table .thead-light th {
  color: #282828;
  background-color: #e9ecef;
  border-color: #282828; }

.table-dark {
  color: #fff;
  background-color: #888; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #757575; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .core-typehead-input, .core-checkable-select-search, .social-md-textarea-textarea {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #282828;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .core-typehead-input, .core-checkable-select-search, .social-md-textarea-textarea {
      transition: none; } }
  .form-control::-ms-expand, .core-typehead-input::-ms-expand, .core-checkable-select-search::-ms-expand, .social-md-textarea-textarea::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring, .core-typehead-input:-moz-focusring, .core-checkable-select-search:-moz-focusring, .social-md-textarea-textarea:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #282828; }
  .form-control:focus, .core-typehead-input:focus, .core-checkable-select-search:focus, .social-md-textarea-textarea:focus {
    color: #282828;
    background-color: #fff;
    border-color: #95cfeb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .form-control::-webkit-input-placeholder, .core-typehead-input::-webkit-input-placeholder, .core-checkable-select-search::-webkit-input-placeholder, .social-md-textarea-textarea::-webkit-input-placeholder {
    color: #555;
    opacity: 1; }
  .form-control::placeholder, .core-typehead-input::placeholder, .core-checkable-select-search::placeholder, .social-md-textarea-textarea::placeholder {
    color: #555;
    opacity: 1; }
  .form-control:disabled, .core-typehead-input:disabled, .core-checkable-select-search:disabled, .social-md-textarea-textarea:disabled, .form-control[readonly], .core-typehead-input[readonly], .core-checkable-select-search[readonly], .social-md-textarea-textarea[readonly] {
    background-color: #ADAFAE;
    opacity: 1; }

select.form-control:focus::-ms-value, select.core-typehead-input:focus::-ms-value, select.core-checkable-select-search:focus::-ms-value, select.social-md-textarea-textarea:focus::-ms-value {
  color: #282828;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ADAFAE;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.core-typehead-input[size], select.core-checkable-select-search[size], select.social-md-textarea-textarea[size], select.form-control[multiple], select.core-typehead-input[multiple], select.core-checkable-select-search[multiple], select.social-md-textarea-textarea[multiple] {
  height: auto; }

textarea.form-control, textarea.core-typehead-input, textarea.core-checkable-select-search, textarea.social-md-textarea-textarea {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check, .core-checkable-select-list-item {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input, .core-checkable-select-list-checkbox {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label, .core-checkable-select-list-checkbox[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label,
  .core-checkable-select-list-checkbox:disabled ~ .form-check-label {
    color: #555; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input, .form-check-inline .core-checkable-select-list-checkbox {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #77B300; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: #77b300;
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .core-typehead-input:valid, .was-validated .core-checkable-select-search:valid, .was-validated .social-md-textarea-textarea:valid, .form-control.is-valid, .is-valid.core-typehead-input, .is-valid.core-checkable-select-search, .is-valid.social-md-textarea-textarea {
  border-color: #77B300;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377B300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .core-typehead-input:valid:focus, .was-validated .core-checkable-select-search:valid:focus, .was-validated .social-md-textarea-textarea:valid:focus, .form-control.is-valid:focus, .is-valid.core-typehead-input:focus, .is-valid.core-checkable-select-search:focus, .is-valid.social-md-textarea-textarea:focus {
    border-color: #77B300;
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.was-validated textarea.form-control:valid, .was-validated textarea.core-typehead-input:valid, .was-validated textarea.core-checkable-select-search:valid, .was-validated textarea.social-md-textarea-textarea:valid, textarea.form-control.is-valid, textarea.is-valid.core-typehead-input, textarea.is-valid.core-checkable-select-search, textarea.is-valid.social-md-textarea-textarea {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #77B300;
  padding-right: calc(0.75em + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377B300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #77B300;
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .was-validated .core-checkable-select-list-checkbox:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, .is-valid.core-checkable-select-list-checkbox ~ .form-check-label {
  color: #77B300; }

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .core-checkable-select-list-checkbox:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.was-validated .core-checkable-select-list-checkbox:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .is-valid.core-checkable-select-list-checkbox ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.is-valid.core-checkable-select-list-checkbox ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #77B300; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #77B300; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #99e600;
  background-color: #99e600; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #77B300; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #77B300; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #77B300;
  box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #CC0000; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: #cc0000;
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .core-typehead-input:invalid, .was-validated .core-checkable-select-search:invalid, .was-validated .social-md-textarea-textarea:invalid, .form-control.is-invalid, .is-invalid.core-typehead-input, .is-invalid.core-checkable-select-search, .is-invalid.social-md-textarea-textarea {
  border-color: #CC0000;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23CC0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23CC0000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .core-typehead-input:invalid:focus, .was-validated .core-checkable-select-search:invalid:focus, .was-validated .social-md-textarea-textarea:invalid:focus, .form-control.is-invalid:focus, .is-invalid.core-typehead-input:focus, .is-invalid.core-checkable-select-search:focus, .is-invalid.social-md-textarea-textarea:focus {
    border-color: #CC0000;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated textarea.form-control:invalid, .was-validated textarea.core-typehead-input:invalid, .was-validated textarea.core-checkable-select-search:invalid, .was-validated textarea.social-md-textarea-textarea:invalid, textarea.form-control.is-invalid, textarea.is-invalid.core-typehead-input, textarea.is-invalid.core-checkable-select-search, textarea.is-invalid.social-md-textarea-textarea {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #CC0000;
  padding-right: calc(0.75em + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23CC0000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23CC0000' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #CC0000;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .was-validated .core-checkable-select-list-checkbox:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .is-invalid.core-checkable-select-list-checkbox ~ .form-check-label {
  color: #CC0000; }

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .core-checkable-select-list-checkbox:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.was-validated .core-checkable-select-list-checkbox:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .is-invalid.core-checkable-select-list-checkbox ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.is-invalid.core-checkable-select-list-checkbox ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #CC0000; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #CC0000; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: red;
  background-color: red; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #CC0000; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #CC0000; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #CC0000;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check, .form-inline .core-checkable-select-list-item {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .core-typehead-input, .form-inline .core-checkable-select-search, .form-inline .social-md-textarea-textarea {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check, .form-inline .core-checkable-select-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input, .form-inline .core-checkable-select-list-checkbox {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .core-checkable-select-button {
  display: inline-block;
  font-weight: 400;
  color: #ADAFAE;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .core-checkable-select-button {
      transition: none; } }
  .btn:hover, .core-checkable-select-button:hover {
    color: #ADAFAE;
    text-decoration: none; }
  .btn:focus, .core-checkable-select-button:focus, .btn.focus, .focus.core-checkable-select-button {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .btn.disabled, .disabled.core-checkable-select-button, .btn:disabled, .core-checkable-select-button:disabled {
    opacity: 0.65; }

a.btn.disabled, a.disabled.core-checkable-select-button,
fieldset:disabled a.btn,
fieldset:disabled a.core-checkable-select-button {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2A9FD6;
  border-color: #2A9FD6; }
  .btn-primary:hover {
    color: #fff;
    background-color: #2387b7;
    border-color: #2180ac; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #2387b7;
    border-color: #2180ac;
    box-shadow: 0 0 0 0.2rem rgba(74, 173, 220, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2180ac;
    border-color: #1f78a1; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 173, 220, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #555;
  border-color: #555; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c;
    box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #555;
    border-color: #555; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #353535; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }

.btn-success {
  color: #fff;
  background-color: #77B300;
  border-color: #77B300; }
  .btn-success:hover {
    color: #fff;
    background-color: #5e8d00;
    border-color: #558000; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #5e8d00;
    border-color: #558000;
    box-shadow: 0 0 0 0.2rem rgba(139, 190, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #77B300;
    border-color: #77B300; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #558000;
    border-color: #4d7300; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 190, 38, 0.5); }

.btn-info {
  color: #fff;
  background-color: #9933CC;
  border-color: #9933CC; }
  .btn-info:hover {
    color: #fff;
    background-color: #822bad;
    border-color: #7a29a3; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #822bad;
    border-color: #7a29a3;
    box-shadow: 0 0 0 0.2rem rgba(168, 82, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #9933CC;
    border-color: #9933CC; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #7a29a3;
    border-color: #732699; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 82, 212, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #FF8800;
  border-color: #FF8800; }
  .btn-warning:hover {
    color: #fff;
    background-color: #d97400;
    border-color: #cc6d00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #d97400;
    border-color: #cc6d00;
    box-shadow: 0 0 0 0.2rem rgba(255, 154, 38, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #FF8800;
    border-color: #FF8800; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc6d00;
    border-color: #bf6600; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 154, 38, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #CC0000;
  border-color: #CC0000; }
  .btn-danger:hover {
    color: #fff;
    background-color: #a60000;
    border-color: #990000; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #a60000;
    border-color: #990000;
    box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #CC0000;
    border-color: #CC0000; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #990000;
    border-color: #8c0000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5); }

.btn-light, .core-checkable-select-button {
  color: #fff;
  background-color: #222;
  border-color: #222; }
  .btn-light:hover, .core-checkable-select-button:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909; }
  .btn-light:focus, .core-checkable-select-button:focus, .btn-light.focus, .focus.core-checkable-select-button {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909;
    box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }
  .btn-light.disabled, .disabled.core-checkable-select-button, .btn-light:disabled, .core-checkable-select-button:disabled {
    color: #fff;
    background-color: #222;
    border-color: #222; }
  .btn-light:not(:disabled):not(.disabled):active, .core-checkable-select-button:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .core-checkable-select-button:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle,
  .show > .dropdown-toggle.core-checkable-select-button {
    color: #fff;
    background-color: #090909;
    border-color: #020202; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .core-checkable-select-button:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .core-checkable-select-button:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus,
    .show > .dropdown-toggle.core-checkable-select-button:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #ADAFAE;
  border-color: #ADAFAE; }
  .btn-dark:hover {
    color: #fff;
    background-color: #9a9c9b;
    border-color: #939695; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #9a9c9b;
    border-color: #939695;
    box-shadow: 0 0 0 0.2rem rgba(185, 187, 186, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #ADAFAE;
    border-color: #ADAFAE; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #939695;
    border-color: #8d908e; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(185, 187, 186, 0.5); }

.btn-outline-primary {
  color: #2A9FD6;
  border-color: #2A9FD6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2A9FD6;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.5); }

.btn-outline-secondary {
  color: #555;
  border-color: #555; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #555;
    border-color: #555; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #555;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #555;
    border-color: #555; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.btn-outline-success {
  color: #77B300;
  border-color: #77B300; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #77B300;
    border-color: #77B300; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #77B300;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #77B300;
    border-color: #77B300; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.5); }

.btn-outline-info {
  color: #9933CC;
  border-color: #9933CC; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #9933CC;
    border-color: #9933CC; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 51, 204, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #9933CC;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #9933CC;
    border-color: #9933CC; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 51, 204, 0.5); }

.btn-outline-warning {
  color: #FF8800;
  border-color: #FF8800; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #FF8800;
    border-color: #FF8800; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FF8800;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #FF8800;
    border-color: #FF8800; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.5); }

.btn-outline-danger {
  color: #CC0000;
  border-color: #CC0000; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #CC0000;
    border-color: #CC0000; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #CC0000;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #CC0000;
    border-color: #CC0000; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }

.btn-outline-light {
  color: #222;
  border-color: #222; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #222;
    border-color: #222; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #222;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #222;
    border-color: #222; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.btn-outline-dark {
  color: #ADAFAE;
  border-color: #ADAFAE; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #ADAFAE;
    border-color: #ADAFAE; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 175, 174, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #ADAFAE;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #ADAFAE;
    border-color: #ADAFAE; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(173, 175, 174, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2A9FD6;
  text-decoration: none; }
  .btn-link:hover {
    color: #1d7097;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #555;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .core-checkable-select-button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .core-checkable-select-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block, .core-checkable-select-button {
  display: block;
  width: 100%; }
  .btn-block + .btn-block, .core-checkable-select-button + .btn-block, .btn-block + .core-checkable-select-button, .core-checkable-select-button + .core-checkable-select-button {
    margin-top: 0.5rem; }

input[type="submit"].btn-block, input.core-checkable-select-button[type="submit"],
input[type="reset"].btn-block,
input.core-checkable-select-button[type="reset"],
input[type="button"].btn-block,
input.core-checkable-select-button[type="button"] {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu, .core-chips-input-panel, .core-chips-input-list-item, .core-checkable-select-panel {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #ADAFAE;
  text-align: left;
  list-style: none;
  background-color: #282828;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu, .dropup .core-chips-input-panel, .dropup .core-chips-input-list-item, .dropup .core-checkable-select-panel {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu, .dropright .core-chips-input-panel, .dropright .core-chips-input-list-item, .dropright .core-checkable-select-panel {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu, .dropleft .core-chips-input-panel, .dropleft .core-chips-input-list-item, .dropleft .core-checkable-select-panel {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .core-chips-input-panel[x-placement^="top"], .core-chips-input-list-item[x-placement^="top"], .core-checkable-select-panel[x-placement^="top"], .dropdown-menu[x-placement^="right"], .core-chips-input-panel[x-placement^="right"], .core-chips-input-list-item[x-placement^="right"], .core-checkable-select-panel[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .core-chips-input-panel[x-placement^="bottom"], .core-chips-input-list-item[x-placement^="bottom"], .core-checkable-select-panel[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .core-chips-input-panel[x-placement^="left"], .core-chips-input-list-item[x-placement^="left"], .core-checkable-select-panel[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #222; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2A9FD6; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2A9FD6; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #555;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show, .show.core-chips-input-panel, .show.core-chips-input-list-item, .show.core-checkable-select-panel {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #555;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #fff; }

.btn-group, .core-chips-container, .core-checkable-select,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .core-chips-container > .btn, .core-checkable-select > .btn, .btn-group > .core-checkable-select-button, .core-chips-container > .core-checkable-select-button, .core-checkable-select > .core-checkable-select-button,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .core-checkable-select-button {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .core-chips-container > .btn:hover, .core-checkable-select > .btn:hover, .btn-group > .core-checkable-select-button:hover, .core-chips-container > .core-checkable-select-button:hover, .core-checkable-select > .core-checkable-select-button:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .core-checkable-select-button:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .core-chips-container > .btn:focus, .core-checkable-select > .btn:focus, .btn-group > .core-checkable-select-button:focus, .core-chips-container > .core-checkable-select-button:focus, .core-checkable-select > .core-checkable-select-button:focus, .btn-group > .btn:active, .core-chips-container > .btn:active, .core-checkable-select > .btn:active, .btn-group > .core-checkable-select-button:active, .core-chips-container > .core-checkable-select-button:active, .core-checkable-select > .core-checkable-select-button:active, .btn-group > .btn.active, .core-chips-container > .btn.active, .core-checkable-select > .btn.active, .btn-group > .active.core-checkable-select-button, .core-chips-container > .active.core-checkable-select-button, .core-checkable-select > .active.core-checkable-select-button,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .core-checkable-select-button:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .core-checkable-select-button:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.core-checkable-select-button {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .core-chips-container > .btn:not(:first-child), .core-checkable-select > .btn:not(:first-child), .btn-group > .core-checkable-select-button:not(:first-child), .core-chips-container > .core-checkable-select-button:not(:first-child), .core-checkable-select > .core-checkable-select-button:not(:first-child),
.btn-group > .btn-group:not(:first-child),
.core-chips-container > .btn-group:not(:first-child),
.core-checkable-select > .btn-group:not(:first-child),
.btn-group > .core-chips-container:not(:first-child),
.core-chips-container > .core-chips-container:not(:first-child),
.core-checkable-select > .core-chips-container:not(:first-child),
.btn-group > .core-checkable-select:not(:first-child),
.core-chips-container > .core-checkable-select:not(:first-child),
.core-checkable-select > .core-checkable-select:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .core-chips-container > .btn:not(:last-child):not(.dropdown-toggle), .core-checkable-select > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle), .core-chips-container > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle), .core-checkable-select > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.core-chips-container > .btn-group:not(:last-child) > .btn,
.core-checkable-select > .btn-group:not(:last-child) > .btn,
.btn-group > .core-chips-container:not(:last-child) > .btn,
.core-chips-container > .core-chips-container:not(:last-child) > .btn,
.core-checkable-select > .core-chips-container:not(:last-child) > .btn,
.btn-group > .core-checkable-select:not(:last-child) > .btn,
.core-chips-container > .core-checkable-select:not(:last-child) > .btn,
.core-checkable-select > .core-checkable-select:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .core-checkable-select-button,
.core-chips-container > .btn-group:not(:last-child) > .core-checkable-select-button,
.core-checkable-select > .btn-group:not(:last-child) > .core-checkable-select-button,
.btn-group > .core-chips-container:not(:last-child) > .core-checkable-select-button,
.core-chips-container > .core-chips-container:not(:last-child) > .core-checkable-select-button,
.core-checkable-select > .core-chips-container:not(:last-child) > .core-checkable-select-button,
.btn-group > .core-checkable-select:not(:last-child) > .core-checkable-select-button,
.core-chips-container > .core-checkable-select:not(:last-child) > .core-checkable-select-button,
.core-checkable-select > .core-checkable-select:not(:last-child) > .core-checkable-select-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .core-chips-container > .btn:not(:first-child), .core-checkable-select > .btn:not(:first-child), .btn-group > .core-checkable-select-button:not(:first-child), .core-chips-container > .core-checkable-select-button:not(:first-child), .core-checkable-select > .core-checkable-select-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.core-chips-container > .btn-group:not(:first-child) > .btn,
.core-checkable-select > .btn-group:not(:first-child) > .btn,
.btn-group > .core-chips-container:not(:first-child) > .btn,
.core-chips-container > .core-chips-container:not(:first-child) > .btn,
.core-checkable-select > .core-chips-container:not(:first-child) > .btn,
.btn-group > .core-checkable-select:not(:first-child) > .btn,
.core-chips-container > .core-checkable-select:not(:first-child) > .btn,
.core-checkable-select > .core-checkable-select:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .core-checkable-select-button,
.core-chips-container > .btn-group:not(:first-child) > .core-checkable-select-button,
.core-checkable-select > .btn-group:not(:first-child) > .core-checkable-select-button,
.btn-group > .core-chips-container:not(:first-child) > .core-checkable-select-button,
.core-chips-container > .core-chips-container:not(:first-child) > .core-checkable-select-button,
.core-checkable-select > .core-chips-container:not(:first-child) > .core-checkable-select-button,
.btn-group > .core-checkable-select:not(:first-child) > .core-checkable-select-button,
.core-chips-container > .core-checkable-select:not(:first-child) > .core-checkable-select-button,
.core-checkable-select > .core-checkable-select:not(:first-child) > .core-checkable-select-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .core-checkable-select-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .core-checkable-select-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .core-checkable-select-button,
  .btn-group-vertical > .btn-group,
  .btn-group-vertical > .core-chips-container,
  .btn-group-vertical > .core-checkable-select {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .core-checkable-select-button:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child),
  .btn-group-vertical > .core-chips-container:not(:first-child),
  .btn-group-vertical > .core-checkable-select:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .core-chips-container:not(:last-child) > .btn,
  .btn-group-vertical > .core-checkable-select:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-chips-container:not(:last-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-checkable-select:not(:last-child) > .core-checkable-select-button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .core-checkable-select-button:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .core-chips-container:not(:first-child) > .btn,
  .btn-group-vertical > .core-checkable-select:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-chips-container:not(:first-child) > .core-checkable-select-button,
  .btn-group-vertical > .core-checkable-select:not(:first-child) > .core-checkable-select-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .core-checkable-select-button,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .core-chips-container > .btn,
.btn-group-toggle > .core-checkable-select > .btn,
.btn-group-toggle > .btn-group > .core-checkable-select-button,
.btn-group-toggle > .core-chips-container > .core-checkable-select-button,
.btn-group-toggle > .core-checkable-select > .core-checkable-select-button {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .core-checkable-select-button input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .core-chips-container > .btn input[type="radio"],
  .btn-group-toggle > .core-checkable-select > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .core-chips-container > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .core-checkable-select > .core-checkable-select-button input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .core-chips-container > .btn input[type="checkbox"],
  .btn-group-toggle > .core-checkable-select > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .core-checkable-select-button input[type="checkbox"],
  .btn-group-toggle > .core-chips-container > .core-checkable-select-button input[type="checkbox"],
  .btn-group-toggle > .core-checkable-select > .core-checkable-select-button input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .input-group > .core-typehead-input, .input-group > .core-checkable-select-search, .input-group > .social-md-textarea-textarea,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .input-group > .core-typehead-input + .form-control, .input-group > .core-checkable-select-search + .form-control, .input-group > .social-md-textarea-textarea + .form-control, .input-group > .form-control + .core-typehead-input, .input-group > .core-typehead-input + .core-typehead-input, .input-group > .core-checkable-select-search + .core-typehead-input, .input-group > .social-md-textarea-textarea + .core-typehead-input, .input-group > .form-control + .core-checkable-select-search, .input-group > .core-typehead-input + .core-checkable-select-search, .input-group > .core-checkable-select-search + .core-checkable-select-search, .input-group > .social-md-textarea-textarea + .core-checkable-select-search, .input-group > .form-control + .social-md-textarea-textarea, .input-group > .core-typehead-input + .social-md-textarea-textarea, .input-group > .core-checkable-select-search + .social-md-textarea-textarea, .input-group > .social-md-textarea-textarea + .social-md-textarea-textarea,
    .input-group > .form-control + .custom-select,
    .input-group > .core-typehead-input + .custom-select,
    .input-group > .core-checkable-select-search + .custom-select,
    .input-group > .social-md-textarea-textarea + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .core-typehead-input + .custom-file,
    .input-group > .core-checkable-select-search + .custom-file,
    .input-group > .social-md-textarea-textarea + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .core-typehead-input,
    .input-group > .form-control-plaintext + .core-checkable-select-search,
    .input-group > .form-control-plaintext + .social-md-textarea-textarea,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .core-typehead-input,
    .input-group > .custom-select + .core-checkable-select-search,
    .input-group > .custom-select + .social-md-textarea-textarea,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .core-typehead-input,
    .input-group > .custom-file + .core-checkable-select-search,
    .input-group > .custom-file + .social-md-textarea-textarea,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .input-group > .core-typehead-input:focus, .input-group > .core-checkable-select-search:focus, .input-group > .social-md-textarea-textarea:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child), .input-group > .core-typehead-input:not(:last-child), .input-group > .core-checkable-select-search:not(:last-child), .input-group > .social-md-textarea-textarea:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child), .input-group > .core-typehead-input:not(:first-child), .input-group > .core-checkable-select-search:not(:first-child), .input-group > .social-md-textarea-textarea:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .core-checkable-select-button,
  .input-group-append .btn,
  .input-group-append .core-checkable-select-button {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .core-checkable-select-button:focus,
    .input-group-append .btn:focus,
    .input-group-append .core-checkable-select-button:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .core-checkable-select-button + .btn, .input-group-prepend .btn + .core-checkable-select-button, .input-group-prepend .core-checkable-select-button + .core-checkable-select-button,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .core-checkable-select-button + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .core-checkable-select-button,
  .input-group-append .btn + .btn,
  .input-group-append .core-checkable-select-button + .btn,
  .input-group-append .btn + .core-checkable-select-button,
  .input-group-append .core-checkable-select-button + .core-checkable-select-button,
  .input-group-append .btn + .input-group-text,
  .input-group-append .core-checkable-select-button + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .core-checkable-select-button {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #282828;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > .core-typehead-input:not(textarea), .input-group-lg > .core-checkable-select-search:not(textarea), .input-group-lg > .social-md-textarea-textarea:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .input-group-lg > .core-typehead-input, .input-group-lg > .core-checkable-select-search, .input-group-lg > .social-md-textarea-textarea,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .core-checkable-select-button,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .core-checkable-select-button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > .core-typehead-input:not(textarea), .input-group-sm > .core-checkable-select-search:not(textarea), .input-group-sm > .social-md-textarea-textarea:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .input-group-sm > .core-typehead-input, .input-group-sm > .core-checkable-select-search, .input-group-sm > .social-md-textarea-textarea,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .core-checkable-select-button,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .core-checkable-select-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .core-checkable-select-button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .core-checkable-select-button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .core-checkable-select-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .core-checkable-select-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .core-checkable-select-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .core-checkable-select-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2A9FD6;
    background-color: #2A9FD6; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #95cfeb; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c0e2f3;
    border-color: #c0e2f3; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #555; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #ADAFAE; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #888 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2A9FD6;
  background-color: #2A9FD6; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(42, 159, 214, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(42, 159, 214, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(42, 159, 214, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #888;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(42, 159, 214, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 2rem 0.375rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #282828;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #95cfeb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
    .custom-select:focus::-ms-value {
      color: #282828;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #555;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #282828; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76562rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #95cfeb;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #ADAFAE; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #fff;
  border: 1px solid #282828;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 1rem;
    line-height: 1.5;
    color: #fff;
    content: "Browse";
    background-color: #282828;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #060606, 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #060606, 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #060606, 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2A9FD6;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #c0e2f3; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2A9FD6;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #c0e2f3; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2A9FD6;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #c0e2f3; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #888; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #888; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #888; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #555;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #282828; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #282828; }
    .nav-tabs .nav-link.disabled {
      color: #555;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #282828;
    border-color: #282828; }
  .nav-tabs .dropdown-menu, .nav-tabs .core-chips-input-panel, .nav-tabs .core-chips-input-list-item, .nav-tabs .core-checkable-select-panel {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2A9FD6; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu, .navbar-nav .core-chips-input-panel, .navbar-nav .core-chips-input-list-item, .navbar-nav .core-checkable-select-panel {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu, .navbar-expand-sm .navbar-nav .core-chips-input-panel, .navbar-expand-sm .navbar-nav .core-chips-input-list-item, .navbar-expand-sm .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu, .navbar-expand-md .navbar-nav .core-chips-input-panel, .navbar-expand-md .navbar-nav .core-chips-input-list-item, .navbar-expand-md .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu, .navbar-expand-lg .navbar-nav .core-chips-input-panel, .navbar-expand-lg .navbar-nav .core-chips-input-list-item, .navbar-expand-lg .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu, .navbar-expand-xl .navbar-nav .core-chips-input-panel, .navbar-expand-xl .navbar-nav .core-chips-input-list-item, .navbar-expand-xl .navbar-nav .core-checkable-select-panel {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu, .navbar-expand .navbar-nav .core-chips-input-panel, .navbar-expand .navbar-nav .core-chips-input-list-item, .navbar-expand .navbar-nav .core-checkable-select-panel {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #282828;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child, .card > .core-checkable-select-list:first-child .list-group-item:first-child, .card > .social-md-textarea-emoji-suggestions:first-child .list-group-item:first-child, .card > .list-group:first-child .social-md-textarea-emoji-suggestions-item:first-child, .card > .core-checkable-select-list:first-child .social-md-textarea-emoji-suggestions-item:first-child, .card > .social-md-textarea-emoji-suggestions:first-child .social-md-textarea-emoji-suggestions-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child, .card > .core-checkable-select-list:last-child .list-group-item:last-child, .card > .social-md-textarea-emoji-suggestions:last-child .list-group-item:last-child, .card > .list-group:last-child .social-md-textarea-emoji-suggestions-item:last-child, .card > .core-checkable-select-list:last-child .social-md-textarea-emoji-suggestions-item:last-child, .card > .social-md-textarea-emoji-suggestions:last-child .social-md-textarea-emoji-suggestions-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child, .card-header + .core-checkable-select-list .list-group-item:first-child, .card-header + .social-md-textarea-emoji-suggestions .list-group-item:first-child, .card-header + .list-group .social-md-textarea-emoji-suggestions-item:first-child, .card-header + .core-checkable-select-list .social-md-textarea-emoji-suggestions-item:first-child, .card-header + .social-md-textarea-emoji-suggestions .social-md-textarea-emoji-suggestions-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #282828;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #555;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #555; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fff;
  background-color: #282828;
  border: 1px solid transparent; }
  .page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #2A9FD6;
    border-color: transparent; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2A9FD6;
  border-color: #2A9FD6; }

.page-item.disabled .page-link {
  color: #555;
  pointer-events: none;
  cursor: auto;
  background-color: #282828;
  border-color: transparent; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge, .core-chips-tag {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge, .core-chips-tag {
      transition: none; } }
  a.badge:hover, a.core-chips-tag:hover, a.badge:focus, a.core-chips-tag:focus {
    text-decoration: none; }
  .badge:empty, .core-chips-tag:empty {
    display: none; }

.btn .badge, .core-checkable-select-button .badge, .btn .core-chips-tag, .core-checkable-select-button .core-chips-tag {
  position: relative;
  top: -1px; }

.badge-pill, .core-chips-tag {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2A9FD6; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #2180ac; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(42, 159, 214, 0.5); }

.badge-secondary, .core-chips-tag {
  color: #fff;
  background-color: #555; }
  a.badge-secondary:hover, a.core-chips-tag:hover, a.badge-secondary:focus, a.core-chips-tag:focus {
    color: #fff;
    background-color: #3c3c3c; }
  a.badge-secondary:focus, a.core-chips-tag:focus, a.badge-secondary.focus, a.focus.core-chips-tag {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.badge-success {
  color: #fff;
  background-color: #77B300; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #558000; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(119, 179, 0, 0.5); }

.badge-info {
  color: #fff;
  background-color: #9933CC; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #7a29a3; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(153, 51, 204, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #FF8800; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #cc6d00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 136, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #CC0000; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #990000; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5); }

.badge-light {
  color: #fff;
  background-color: #222; }
  a.badge-light:hover, a.badge-light:focus {
    color: #fff;
    background-color: #090909; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #ADAFAE; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #939695; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 175, 174, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #282828;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #16536f;
  background-color: #d4ecf7;
  border-color: #c3e4f4; }
  .alert-primary hr {
    border-top-color: #addaf0; }
  .alert-primary .alert-link {
    color: #0e3344; }

.alert-secondary {
  color: #2c2c2c;
  background-color: #dddddd;
  border-color: #cfcfcf; }
  .alert-secondary hr {
    border-top-color: #c2c2c2; }
  .alert-secondary .alert-link {
    color: #131313; }

.alert-success {
  color: #3e5d00;
  background-color: #e4f0cc;
  border-color: #d9eab8; }
  .alert-success hr {
    border-top-color: #cee4a4; }
  .alert-success .alert-link {
    color: #1c2a00; }

.alert-info {
  color: #501b6a;
  background-color: #ebd6f5;
  border-color: #e2c6f1; }
  .alert-info hr {
    border-top-color: #d8b2ec; }
  .alert-info .alert-link {
    color: #311141; }

.alert-warning {
  color: #854700;
  background-color: #ffe7cc;
  border-color: #ffdeb8; }
  .alert-warning hr {
    border-top-color: #ffd29f; }
  .alert-warning .alert-link {
    color: #522c00; }

.alert-danger {
  color: #6a0000;
  background-color: #f5cccc;
  border-color: #f1b8b8; }
  .alert-danger hr {
    border-top-color: #eda3a3; }
  .alert-danger .alert-link {
    color: #370000; }

.alert-light {
  color: #121212;
  background-color: lightgray;
  border-color: #c1c1c1; }
  .alert-light hr {
    border-top-color: #b4b4b4; }
  .alert-light .alert-link {
    color: black; }

.alert-dark {
  color: #5a5b5a;
  background-color: #efefef;
  border-color: #e8e9e8; }
  .alert-dark hr {
    border-top-color: #dbdddb; }
  .alert-dark .alert-link {
    color: #414141; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #282828;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2A9FD6;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group, .core-checkable-select-list, .social-md-textarea-emoji-suggestions {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #282828;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #282828;
    text-decoration: none;
    background-color: #2A9FD6; }
  .list-group-item-action:active {
    color: #ADAFAE;
    background-color: #2A9FD6; }

.list-group-item, .social-md-textarea-emoji-suggestions-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #222;
  border: 1px solid #282828; }
  .list-group-item:first-child, .social-md-textarea-emoji-suggestions-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child, .social-md-textarea-emoji-suggestions-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .disabled.social-md-textarea-emoji-suggestions-item, .list-group-item:disabled, .social-md-textarea-emoji-suggestions-item:disabled {
    color: #555;
    pointer-events: none;
    background-color: #282828; }
  .list-group-item.active, .active.social-md-textarea-emoji-suggestions-item {
    z-index: 2;
    color: #fff;
    background-color: #2A9FD6;
    border-color: #2A9FD6; }
  .list-group-item + .list-group-item, .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-item + .social-md-textarea-emoji-suggestions-item, .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active, .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child, .list-group-horizontal .social-md-textarea-emoji-suggestions-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child, .list-group-horizontal .social-md-textarea-emoji-suggestions-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active, .list-group-horizontal .active.social-md-textarea-emoji-suggestions-item {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active, .list-group-horizontal-sm .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-sm .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-sm .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-sm .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active, .list-group-horizontal-md .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-md .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-md .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-md .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active, .list-group-horizontal-lg .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-lg .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-lg .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-lg .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active, .list-group-horizontal-xl .active.social-md-textarea-emoji-suggestions-item {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .list-group-item, .list-group-horizontal-xl .list-group-item + .social-md-textarea-emoji-suggestions-item, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .social-md-textarea-emoji-suggestions-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .list-group-item.active, .list-group-horizontal-xl .list-group-item + .active.social-md-textarea-emoji-suggestions-item, .list-group-horizontal-xl .social-md-textarea-emoji-suggestions-item + .active.social-md-textarea-emoji-suggestions-item {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item, .list-group-flush .social-md-textarea-emoji-suggestions-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child, .list-group-flush .social-md-textarea-emoji-suggestions-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child, .list-group-flush:last-child .social-md-textarea-emoji-suggestions-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #16536f;
  background-color: #c3e4f4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #16536f;
    background-color: #addaf0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #16536f;
    border-color: #16536f; }

.list-group-item-secondary {
  color: #2c2c2c;
  background-color: #cfcfcf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2c2c2c;
    background-color: #c2c2c2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #2c2c2c; }

.list-group-item-success {
  color: #3e5d00;
  background-color: #d9eab8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #3e5d00;
    background-color: #cee4a4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #3e5d00;
    border-color: #3e5d00; }

.list-group-item-info {
  color: #501b6a;
  background-color: #e2c6f1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #501b6a;
    background-color: #d8b2ec; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #501b6a;
    border-color: #501b6a; }

.list-group-item-warning {
  color: #854700;
  background-color: #ffdeb8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #854700;
    background-color: #ffd29f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854700;
    border-color: #854700; }

.list-group-item-danger {
  color: #6a0000;
  background-color: #f1b8b8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6a0000;
    background-color: #eda3a3; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6a0000;
    border-color: #6a0000; }

.list-group-item-light {
  color: #121212;
  background-color: #c1c1c1; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #121212;
    background-color: #b4b4b4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #121212;
    border-color: #121212; }

.list-group-item-dark {
  color: #5a5b5a;
  background-color: #e8e9e8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #5a5b5a;
    background-color: #dbdddb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #5a5b5a;
    border-color: #5a5b5a; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #fff;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #555;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #222;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #282828;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #282828;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #282828; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #282828; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #282828; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #282828; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #282828;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #282828;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #282828; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #282828; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #282828; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #202020; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #282828; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  background-color: #202020;
  border-bottom: 1px solid #141414;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #ADAFAE; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary, .core-chips-input-item-active {
  background-color: #2A9FD6 !important; }

a.bg-primary:hover, a.core-chips-input-item-active:hover, a.bg-primary:focus, a.core-chips-input-item-active:focus,
button.bg-primary:hover,
button.core-chips-input-item-active:hover,
button.bg-primary:focus,
button.core-chips-input-item-active:focus {
  background-color: #2180ac !important; }

.bg-secondary {
  background-color: #555 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3c3c3c !important; }

.bg-success {
  background-color: #77B300 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #558000 !important; }

.bg-info {
  background-color: #9933CC !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #7a29a3 !important; }

.bg-warning {
  background-color: #FF8800 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc6d00 !important; }

.bg-danger {
  background-color: #CC0000 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #990000 !important; }

.bg-light {
  background-color: #222 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #090909 !important; }

.bg-dark {
  background-color: #ADAFAE !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #939695 !important; }

.bg-white, .core-chips-input-container {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .core-chips-input-container {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right, .social-message-input-tab,
.social-message-preview-tab {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom, .social-message-input-tab,
.social-message-preview-tab {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left, .social-message-input-tab,
.social-message-preview-tab {
  border-left: 1px solid #dee2e6 !important; }

.border-0, .core-chips-input {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2A9FD6 !important; }

.border-secondary {
  border-color: #555 !important; }

.border-success {
  border-color: #77B300 !important; }

.border-info {
  border-color: #9933CC !important; }

.border-warning {
  border-color: #FF8800 !important; }

.border-danger, .tweet-input-message-warning {
  border-color: #CC0000 !important; }

.border-light {
  border-color: #222 !important; }

.border-dark {
  border-color: #ADAFAE !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded, .core-chips-input-container {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom, .social-message-input-tab,
.social-message-preview-tab {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .core-chips-container, .core-chips-input-container, .core-chips-tag, .core-checkable-select, .core-checkable-select-button, .authentication-form, .idea-comment, .idea-comment-header, .tweet-input {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row, .core-chips-input-container, .core-chips-tag, .idea-comment-header {
  flex-direction: row !important; }

.flex-column, .authentication-form, .idea-comment, .tweet-input {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap, .core-chips-input-container {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill, .core-chips-input {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between, .core-checkable-select-button {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .core-chips-tag, .core-checkable-select-button, .idea-comment-header {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto, .core-typehead-suggestions {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative, .core-typehead, .social-md-textarea {
  position: relative !important; }

.position-absolute, .core-typehead-suggestions, .social-md-textarea-emoji-suggestions {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100, .core-typehead-suggestions, .core-chips-input-container, .core-chips-input-panel, .core-chips-input-list-item {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0, .announcement-banner-message > p,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0, .core-chips-tag,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .core-checkable-select-list-item, .authentication-label,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2, .core-chips-input, .core-chips-tag {
  margin: 0.5rem !important; }

.mt-2, .core-checkable-select-list, .authentication-password-label, .authentication-submit-button, .authentication-disclaimer, .idea-comment-message,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .core-chips-tag-icon, .idea-comment-created,
.idea-comment-report,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .idea-comment,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1, .core-typehead-suggestions-item, .core-chips-input-panel, .core-chips-input-list-item, .core-checkable-select-panel, .authentication-form, .social-md-textarea-emoji-suggestions-item {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2, .social-message-input-tab,
.social-message-preview-tab {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.social-message-preview-tab,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.social-message-preview-tab,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right, .tweet-input-count {
  text-align: right !important; }

.text-center, .authentication-title {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light, .authentication-display-mode-label, .authentication-disclaimer {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold, .idea-comment-username {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.core-chips-input::-webkit-input-placeholder {
  font-style: italic !important; }

.font-italic, .core-chips-input::placeholder, .authentication-display-mode-label {
  font-style: italic !important; }

.text-white, .core-chips-input-item-active {
  color: #fff !important; }

.text-primary, .link-button {
  color: #2A9FD6 !important; }

a.text-primary:hover, a.link-button:hover, a.text-primary:focus, a.link-button:focus {
  color: #1d7097 !important; }

.text-secondary {
  color: #555 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #2f2f2f !important; }

.text-success {
  color: #77B300 !important; }

a.text-success:hover, a.text-success:focus {
  color: #446700 !important; }

.text-info {
  color: #9933CC !important; }

a.text-info:hover, a.text-info:focus {
  color: #6b248f !important; }

.text-warning {
  color: #FF8800 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b35f00 !important; }

.text-danger, .tweet-input-color-warning {
  color: #CC0000 !important; }

a.text-danger:hover, a.tweet-input-color-warning:hover, a.text-danger:focus, a.tweet-input-color-warning:focus {
  color: maroon !important; }

.text-light, .core-chips-tag-icon:hover {
  color: #222 !important; }

a.text-light:hover, a.core-chips-tag-icon:hover, a.text-light:focus, a.core-chips-tag-icon:focus:hover {
  color: black !important; }

.text-dark {
  color: #ADAFAE !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #868988 !important; }

.text-body {
  color: #ADAFAE !important; }

.text-muted, .tweet-input {
  color: #555 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.core-checkable-select-button) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #888;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge, .core-chips-tag {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #282828; }
  .table .thead-dark th {
    color: inherit;
    border-color: #282828; } }

.min-height-0 {
  min-height: 0; }

.min-width-0 {
  min-width: 0; }

.unselectable {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none; }

.clickable, .link-button {
  cursor: pointer; }

.link-button {
  text-decoration: none; }

.link-button:hover {
  text-decoration: underline; }

.core-typehead-suggestions {
  max-height: 300px; }

.core-chips-input:focus {
  outline: none; }

.core-chips-tag-icon:hover {
  cursor: pointer; }

.core-chips-input-list {
  max-height: 200px;
  overflow: auto; }

.core-checkable-select-panel {
  min-width: 100%; }

.core-checkable-select-list {
  max-height: 200px;
  overflow: auto; }

.core-checkable-select-list-item {
  padding-left: 0; }

.core-checkable-select-list-checkbox {
  cursor: pointer; }

.core-checkable-select-list-label {
  cursor: pointer; }

.rc-progress {
  margin: 5px 0; }
  .rc-progress .rc-progress-percent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: black;
    font-size: 10px;
    font-weight: 500;
    z-index: 1; }
  .rc-progress .progress {
    position: relative; }
  .rc-progress .rc-progress-text {
    text-align: center;
    width: 100%;
    color: black;
    font-size: 10px; }

.tutorial-wizard-bubble {
  background-color: #56bbdc;
  border-color: #56bbdc;
  padding: 15px;
  z-index: 1030;
  max-width: 800px; }

.tutorial-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 20; }

.tutorial-title {
  font-weight: 300; }

.tutorial-text {
  font-weight: 300;
  text-align: justify; }

.tutorial-close:hover {
  cursor: pointer;
  color: #91d4eb; }

.tutorial-pagination {
  font-size: 14px; }

.tutorial-next-button {
  color: #56bbdc; }

.tutorial-element-disabled {
  pointer-events: none; }

.tutorial-element-highlight {
  box-shadow: 0 0 10px #f8ffcf;
  border: 1px solid #f8ffcf;
  z-index: 50;
  position: relative;
  background-color: white;
  padding: 5px;
  border-radius: 5px; }

#arrow,
#arrow::before {
  position: absolute;
  width: 20px;
  height: 20px;
  background: inherit; }

#arrow {
  visibility: hidden; }

#arrow::before {
  visibility: visible;
  content: '';
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -10px; }

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -10px; }

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -10px; }

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -10px; }

.authentication-disclaimer {
  font-size: 12px; }

.idea-comment-header {
  font-size: 12px; }

.idea-comment-created,
.idea-comment-report {
  color: #555; }

.social-md-textarea-emoji-suggestions {
  z-index: 1; }

.social-message-preview-tab {
  min-height: 103px; }

.idea-report-button-unreported {
  cursor: pointer; }
  .idea-report-button-unreported:hover {
    color: #CC0000; }

.video-player-loading {
  position: absolute !important;
  justify-content: center !important;
  display: flex !important;
  color: #ced4da;
  margin: 0;
  top: 50%;
  z-index: 10;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.video-player .player-bg {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 150px;
  color: lightgray; }

.video-player .player-tracks {
  z-index: 2; }

.player-timeline .btn.focus, .player-timeline .focus.core-checkable-select-button,
.player-timeline .btn:focus,
.player-timeline .core-checkable-select-button:focus {
  outline: 0 !important;
  box-shadow: none !important; }

.cursor-none:hover {
  cursor: none; }

.cursor-move:hover {
  cursor: move; }

.unselectable-text {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none; }

.overflow-x-auto {
  overflow-x: auto; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-auto {
  min-height: 0; }

.overflow-x-auto {
  min-width: 0; }

.min-width-100 {
  min-width: 100%; }

.z-index-4 {
  z-index: 10; }

.timeline-body {
  border: 0; }

.timeline-ruler {
  min-height: 25px;
  height: 25px;
  border-bottom: 1px solid #808080; }

.timeline-ruler-tick {
  border-left: 1px solid #808080;
  height: 12px;
  margin-top: 13px; }

.timeline-ruler-tick-ms {
  border-left: 1px solid #808080;
  height: 5px;
  margin-top: 20px; }

.timeline-ruler-time {
  font-size: 10px;
  margin-top: 0px; }

.timeline-ruler-cursor,
.timeline-ruler-cursor-preview {
  margin-top: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid; }

.timeline-ruler-cursor {
  border-top-color: #007bff; }

.timeline-ruler-cursor-preview {
  border-top-color: #b4b4b4; }

.timeline-track {
  border-bottom: 1px solid #808080;
  min-height: 30px;
  height: 30px; }

.timeline-track:hover {
  cursor: pointer; }

.timeline-track-header-text {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .timeline-track-header-text svg.timeline-track-header-icon {
    width: 30px !important;
    height: 20px !important; }

.timeline-track-section {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
  bottom: 2px;
  height: 26px;
  z-index: 1;
  opacity: 0.8; }

.timeline-track-section:hover {
  opacity: 1; }

.timeline-track-section-inner {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
  text-align: center; }
  .timeline-track-section-inner.movable:hover {
    cursor: move; }

.timeline-track-section-selected .timeline-track-section-inner {
  border-color: dodgerblue; }

.timeline-track-section-grip-left,
.timeline-track-section-grip-right {
  position: absolute;
  padding: 0;
  margin: 0;
  border: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background-color: transparent !important;
  z-index: 10; }
  .timeline-track-section-grip-left.resizable:hover,
  .timeline-track-section-grip-right.resizable:hover {
    cursor: ew-resize; }

.timeline-track-section-transition {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
  bottom: 2px;
  height: 26px;
  z-index: 15; }
  .timeline-track-section-transition .timeline-track-section-inner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    min-width: 18px; }

.timeline-track-section-transition-selected {
  opacity: 0.7;
  background-color: lightgreen !important;
  border: 2px solid lightgreen;
  border-color: dodgerblue; }
  .timeline-track-section-transition-selected .icon-transition {
    color: lightgreen; }

.icon-transition {
  background: black;
  padding: 2px;
  border: black 1px solid;
  border-radius: 2px;
  cursor: pointer;
  transition: height 0.3s; }

.icon-transition-on-drag {
  background: blue;
  height: 90%; }

.timeline-track-section-grip-left {
  left: -3px; }

.timeline-track-section-grip-right {
  right: -3px; }

.timeline-track-marker {
  border-left: 1px solid #808080; }

.timeline-track-cursor-preview {
  border-left: 2px solid #b4b4b4;
  z-index: 10; }

.timeline-track-cursor {
  border-left: 2px solid #007bff;
  z-index: 10; }

.timeline-ruler-border-top {
  border-top: 1px solid #808080; }

.timeline-current-track {
  background-color: #e0e0e0;
  cursor: default; }

.timeline-zoom-display {
  width: 50px; }

.timeline-unselected-track {
  background-color: #ffffff; }

.timeline-diamond-event-hidden {
  padding: 5px;
  opacity: 0;
  z-index: 3; }

.timeline-header-color-transparent {
  color: transparent; }

.timeline-ruler-min-height {
  min-height: 25px; }

.timeline-container-headers-scrollbar::-webkit-scrollbar {
  width: 0 !important; }

.timeline-container-headers-scrollbar {
  -ms-overflow-style: none; }

.timeline-ruler-border-bottom {
  border-bottom: 1px solid #808080; }

.timeline-header-separator {
  left: 0px;
  position: -webkit-sticky !important;
  position: sticky !important; }

.marker-up {
  margin-top: 10px;
  height: 10px;
  width: 12px;
  background-color: #0088d6; }
  :hover > .marker-up {
    cursor: pointer;
    background-color: #808080 !important; }

.marker-down {
  top: 20px;
  border-width: 5px 6px 0 6px;
  border-style: solid;
  border-color: #007bff transparent transparent transparent; }
  :hover > .marker-down {
    cursor: pointer;
    border-top-color: #808080 !important; }

.update-duration-split > .dropdown-menu, .update-duration-split > .core-chips-input-panel, .update-duration-split > .core-chips-input-list-item, .update-duration-split > .core-checkable-select-panel {
  -webkit-transform: translate3d(0px, 33px, 0px) !important;
          transform: translate3d(0px, 33px, 0px) !important;
  min-width: 0 !important; }

.update-playback-rate-dropdown .dropdown-menu, .update-playback-rate-dropdown .core-chips-input-panel, .update-playback-rate-dropdown .core-chips-input-list-item, .update-playback-rate-dropdown .core-checkable-select-panel {
  min-width: 0 !important;
  width: auto; }

.text-black {
  color: black; }

.volume-meter {
  display: flex;
  flex-direction: column;
  margin: 1px 0; }
  .volume-meter .volume-meter-name {
    width: 12px;
    height: 12px;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: #2dff34;
    background-color: #000000;
    margin-left: 1px; }
  .volume-meter.vertical {
    margin: 0 1px; }
    .volume-meter.vertical .volume-meter-name {
      margin-left: 0;
      margin-top: 1px; }

.volume-meter-inner {
  position: relative;
  background: linear-gradient(90deg, #00df28 0%, #ffff2e 80%, #ff6100 100%);
  width: calc(100% - 2px);
  height: 12px;
  min-height: 12px;
  border: 0.8px solid #000000; }
  .volume-meter-inner .gauge-mask {
    background-color: #000000;
    position: absolute;
    width: 0;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0; }

.volume-meter.reversed .volume-meter-inner {
  background: linear-gradient(270deg, #00df28 0%, #ffff2e 80%, #ff6100 100%); }
  .volume-meter.reversed .volume-meter-inner .gauge-mask {
    left: 0;
    right: auto; }

.volume-meter.vertical .volume-meter-inner {
  background: linear-gradient(0deg, #00df28 0%, #ffff2e 80%, #ff6100 100%);
  height: calc(100% - 2px);
  width: 12px;
  min-width: 12px; }
  .volume-meter.vertical .volume-meter-inner .gauge-mask {
    left: 0;
    right: 0;
    height: 0;
    width: auto;
    top: 0;
    bottom: auto; }

.volume-meter.vertical.reversed .volume-meter-inner {
  background: linear-gradient(180deg, #00df28 0%, #ffff2e 80%, #ff6100 100%); }
  .volume-meter.vertical.reversed .volume-meter-inner .gauge-mask {
    top: auto;
    bottom: 0; }

.volume-monitor {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .volume-monitor .name {
    margin: 1px;
    font-size: 9px;
    color: #2dff34;
    background-color: #000000;
    text-align: center;
    font-weight: 500; }
  .volume-monitor .volume-meter-container {
    display: flex;
    height: 100%; }

.volume-panel {
  display: flex;
  margin-left: 10px; }

.times-box {
  display: flex;
  align-items: center; }
  .times-box .tb-switch {
    display: flex;
    margin-right: 8px;
    font-size: 12px;
    align-items: center; }
    .times-box .tb-switch .tb-switch-ts {
      margin-right: 4px; }
    .times-box .tb-switch .tb-switch-ms {
      margin-left: -4px; }
    .times-box .tb-switch .custom-control-label::before,
    .times-box .tb-switch .custom-control-label::after {
      cursor: pointer; }
  .times-box .tb-times {
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px; }

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  width: 35px;
  height: 35px; }

.ab-children {
  background-color: #292929;
  border: 1px solid #616161;
  z-index: 10001; }

.ab-child {
  display: flex;
  align-items: center;
  justify-content: center; }

.ab-child-title {
  margin-left: 8px;
  white-space: nowrap; }

.ab-active {
  color: #fff;
  border-color: #282828;
  background-color: #282828; }

.ab-dropdown {
  position: relative;
  align-self: center; }
  .ab-dropdown .ab-dropdown-content {
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transform: translate(-10%, 102%);
            transform: translate(-10%, 102%);
    border: 1px solid #616161;
    background-color: #2e2e2e;
    border-radius: 4px;
    z-index: 10; }

/* Scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #8b8b8b #535353; }

/* Scrollbar for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px; }

*::-webkit-scrollbar-track {
  background: #535353; }

*::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border: none; }

.btn:focus, .core-checkable-select-button:focus,
.btn.mousedown:focus,
.mousedown.core-checkable-select-button:focus, .form-control, .core-typehead-input, .core-checkable-select-search, .social-md-textarea-textarea, .form-control:focus, .core-typehead-input:focus, .core-checkable-select-search:focus, .social-md-textarea-textarea:focus {
  box-shadow: none; }

.form-control, .core-typehead-input, .core-checkable-select-search, .social-md-textarea-textarea, .form-control:focus, .core-typehead-input:focus, .core-checkable-select-search:focus, .social-md-textarea-textarea:focus {
  border: 1px solid #282828;
  background-color: #282828;
  color: #adafae; }

.form-control:focus, .core-typehead-input:focus, .core-checkable-select-search:focus, .social-md-textarea-textarea:focus {
  border-color: #2a9fd6; }

.form-control:disabled, .core-typehead-input:disabled, .core-checkable-select-search:disabled, .social-md-textarea-textarea:disabled, .form-control[readonly], .core-typehead-input[readonly], .core-checkable-select-search[readonly], .social-md-textarea-textarea[readonly] {
  color: #888;
  background-color: transparent; }

.input-group-text {
  color: #888; }

.btn-outline-primary {
  color: #888;
  border-color: #282828; }
  .btn-outline-primary:hover {
    border-color: #282828;
    background-color: #282828; }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #282828;
  background-color: transparent; }

.btn.disabled, .disabled.core-checkable-select-button, .btn:disabled, .core-checkable-select-button:disabled {
  cursor: not-allowed; }

.navbar.bg-dark {
  background-color: #181818 !important; }

.border, .core-chips-input-container {
  border: 1px solid #282828 !important; }

.border-top {
  border-top: 1px solid #282828 !important; }

.border-right, .social-message-input-tab,
.social-message-preview-tab {
  border-right: 1px solid #282828 !important; }

.border-bottom, .social-message-input-tab,
.social-message-preview-tab {
  border-bottom: 1px solid #282828 !important; }

.border-left, .social-message-input-tab,
.social-message-preview-tab {
  border-left: 1px solid #282828 !important; }

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #888 !important;
  background-color: #181818 !important;
  border-color: #282828 !important;
  box-shadow: none !important; }
  .btn-outline-primary:not(:disabled):not(.disabled):active:hover,
  .btn-outline-primary:not(:disabled):not(.disabled).active:hover,
  .show > .btn-outline-primary.dropdown-toggle:hover {
    background-color: #282828 !important; }

.dropdown-menu, .core-chips-input-panel, .core-chips-input-list-item, .core-checkable-select-panel {
  left: none !important;
  right: 20px !important; }

.dropdown-item {
  color: rgba(255, 255, 255, 0.8) !important;
  transition: 0.2s; }

.dropdown-item a,
.dropdown-item p {
  width: 100% !important;
  font-size: 1.1rem;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.8) !important; }

.dropdown-item a:hover {
  text-decoration: none; }

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.3) !important; }

.dropdown-item svg {
  margin-right: 10px !important; }

.modal-content {
  background-color: #181818 !important; }

.modal-backdrop {
  background-color: #7d7d7d !important; }

.toast {
  background-color: #383838 !important; }

.toast-header {
  background-color: #383838 !important;
  color: #888 !important; }

.no-rounded {
  border-radius: 0 !important;
  border-radius: 0 !important; }

.rc-progress .progress {
  background-color: #929292; }

.rc-progress .rc-progress-percent {
  color: white; }

.rc-progress .rc-progress-text {
  color: white; }

.bg-info {
  background-color: #2a9fd6 !important; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* Make react-bootstrap tabs actually hidden when not selected. */
/* https://stackoverflow.com/questions/23772673/ */
.tab-pane[aria-hidden='true'] {
  display: none !important; }

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column; }

.display-text {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.app-spinner-container {
  height: calc(100% - 40px); }
  .app-spinner-container .app-spinner {
    font-size: 3.5em; }

[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  margin: 6px 0;
  width: 100%; }
  [type='range']::-moz-focus-outer {
    border: 0; }
  [type='range']:focus {
    outline: 0; }
    [type='range']:focus::-webkit-slider-runnable-track {
      background: #b7b7b7; }
    [type='range']:focus::-ms-fill-lower {
      background: #aaaaaa; }
    [type='range']:focus::-ms-fill-upper {
      background: #b7b7b7; }
  [type='range']::-webkit-slider-runnable-track {
    cursor: default;
    height: 2px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 100%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    background: #aaaaaa;
    border: 0 solid #cfd8dc;
    border-radius: 5px; }
  [type='range']::-webkit-slider-thumb {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    background: #aaaaaa;
    border: 1px solid #eceff1;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: default;
    height: 12px;
    width: 12px;
    -webkit-appearance: none;
    margin-top: -5px; }
  [type='range']::-moz-range-track {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    cursor: default;
    height: 2px;
    -moz-transition: all .2s ease;
    transition: all .2s ease;
    width: 100%;
    background: #aaaaaa;
    border: 0 solid #cfd8dc;
    border-radius: 5px;
    height: 1px; }
  [type='range']::-moz-range-thumb {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    background: #aaaaaa;
    border: 1px solid #eceff1;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: default;
    height: 12px;
    width: 12px; }
  [type='range']::-ms-track {
    cursor: default;
    height: 2px;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
    width: 100%;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent; }
  [type='range']::-ms-fill-lower {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    background: #9d9d9d;
    border: 0 solid #cfd8dc;
    border-radius: 10px; }
  [type='range']::-ms-fill-upper {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    background: #aaaaaa;
    border: 0 solid #cfd8dc;
    border-radius: 10px; }
  [type='range']::-ms-thumb {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 0 rgba(13, 13, 13, 0.2);
    background: #aaaaaa;
    border: 1px solid #eceff1;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: default;
    height: 12px;
    width: 12px;
    margin-top: 0.5px; }
  [type='range']:disabled::-webkit-slider-thumb, [type='range']:disabled::-moz-range-thumb, [type='range']:disabled::-ms-thumb, [type='range']:disabled::-webkit-slider-runnable-track, [type='range']:disabled::-ms-fill-lower, [type='range']:disabled::-ms-fill-upper {
    cursor: not-allowed; }

.konvajs-content,
.konvajs-content > canvas {
  width: 100% !important;
  height: 100% !important; }

.video-editor {
  min-height: 0;
  background-color: #181818; }

.video-editor-top {
  min-height: 0;
  border-color: transparent !important; }

.video-editor-top .d-flex {
  border-color: transparent !important; }

.video-editor-bottom {
  min-height: 160px;
  margin-left: 38px;
  width: calc(100% - 38px); }

.video-editor-area-container {
  min-width: 0; }

.video-editor-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; }

.border-wrapper {
  border: 3px solid transparent;
  transition: border-color 0.3s ease-in-out; }

.border-wrapper:hover {
  border-color: #2a9fd6; }

.video-editor-right-panel {
  min-width: 320px;
  width: 320px;
  display: none !important; }

.video-editor-properties {
  overflow-y: auto;
  min-height: 0; }

.video-editor-right-panel .nav-tabs .nav-link,
.video-editor-right-panel .nav-pills .nav-link,
.video-editor-right-panel .nav-tabs .nav-link.active,
.video-editor-right-panel .nav-tabs .nav-item.show .nav-link {
  color: #888; }

.video-editor-right-panel .nav-tabs .nav-link.active,
.video-editor-right-panel .nav-pills .nav-link.active {
  color: #2a9fd6;
  border-color: #2a9fd6;
  border-bottom: 0px;
  background: transparent; }

.video-editor-area-container #ZoomToolbarContainer {
  overflow: hidden; }

.video-editor-area-container #ZoomToolbarContainer .input-group {
  flex-direction: column;
  display: block;
  width: 35px;
  margin-left: 0px;
  margin-top: 10px; }

.video-editor-area-container .btn-group button {
  width: 35px;
  height: 35px;
  font-size: 1.1rem; }

.video-editor-area-container #ZoomToolbarContainer .input-group .btn-group {
  flex-direction: column;
  width: 35px;
  margin-left: 0px; }

.disabled-url-fetching {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  display: none; }

.tracklist-resizer-wrapper {
  width: 100%;
  background: transparent;
  height: 25px;
  display: flex;
  align-items: flex-end; }

.tracklist-resizer {
  width: 100%;
  height: 7px;
  background: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: 0.4s; }

.tracklist-resizer:hover,
.tracklist-resizer:active {
  cursor: ns-resize;
  height: 15px; }

.tracklist-resizer-icon {
  -webkit-transform: scale(2, 0.2);
          transform: scale(2, 0.2);
  transition: 0.4s; }

.tracklist-resizer:hover .tracklist-resizer-icon,
.tracklist-resizer:active .tracklist-resizer-icon {
  -webkit-transform: scale(2, 1);
          transform: scale(2, 1); }

.video-editor-area-container .p-1 .input-group {
  width: 30px; }

.video-editor-area-container .input-group .btn-group {
  flex-direction: column; }

.video-editor-area-container .input-group .btn-group .timeline-zoom-display {
  width: 35px;
  font-size: 0.7rem;
  border-right: 1px solid #272727;
  border-left: 1px solid #272727; }

.resource-browser-column-split {
  display: none; }

.highlighted {
  color: #2a9fd6;
  background: #292929; }

.timeline-container .dropdown-menu {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.resource-file-view .resource-preview-container {
  text-align: center; }

.resource-file-view .resource-preview {
  max-height: 300px; }

.resource-gallery-modal .resource-gallery-bg {
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5); }

.resource-gallery-modal .resource-gallery-img-body {
  top: 0;
  left: 0;
  z-index: 10001; }

.resource-gallery-modal .resource-gallery-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 30px; }

.resource-gallery-modal .resource-gallery-video {
  object-fit: contain; }

.resource-gallery-modal .resource-gallery-controls {
  top: 0;
  left: 0;
  z-index: 10002; }

.resource-gallery-modal .resource-gallery-controls,
.resource-gallery-modal .resource-gallery-btn {
  color: #fff; }

.thumbnail-card {
  position: relative;
  border: 3px solid transparent; }
  .thumbnail-card.thumbnail-card-selected {
    border-color: #2a9fd6; }
  .thumbnail-card .thumbnail-card-name {
    text-overflow: ellipsis;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 2px;
    font-size: 12px; }
  .thumbnail-card .thumbnail-card-options-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7); }

.resource-browser {
  height: 100%; }
  .resource-browser .dropdown .dropdown-item {
    padding: 0.25rem 0.75rem; }

.add-personal-file-progress .upload-progress-title {
  text-align: center;
  margin-bottom: 14px;
  font-size: 20px; }

.add-personal-file-progress .upload-progress-filename {
  text-align: center;
  margin-bottom: 5px;
  font-size: 16px; }

.transition-preview-container {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .transition-preview-container .transition-preview-block {
    display: flex;
    flex-direction: column; }
    .transition-preview-container .transition-preview-block .transition-preview {
      border-radius: 2px;
      cursor: pointer;
      max-width: 200px;
      height: auto; }
    .transition-preview-container .transition-preview-block .transition-preview.selected {
      outline: 2px solid dodgerblue; }
    .transition-preview-container .transition-preview-block .transition-video {
      position: absolute; }

.es-title {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 5px; }

.duration-wrapper {
  display: flex; }
  .duration-wrapper .es-duration-input {
    max-width: 200px;
    margin-top: 0;
    padding: 0 5px; }

.edit-section {
  color: white;
  height: 100%; }
  .edit-section .es-field {
    padding: 8px 0; }
    .edit-section .es-field.es-field-small input {
      width: 60px; }
    .edit-section .es-field label {
      margin-bottom: 4px; }
  .edit-section .es-header .es-header-title {
    font-size: 25px;
    text-transform: capitalize; }
  .edit-section .es-body {
    padding: 5%;
    overflow: auto;
    height: 90%; }
    .edit-section .es-body .text-editor {
      background: transparent;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 5px;
      color: rgba(255, 255, 255, 0.8);
      width: 100%;
      padding: 4px 8px; }
    .edit-section .es-body .es-title {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
      margin: 0px;
      margin-bottom: 5px; }
    .edit-section .es-body .es-input {
      font-size: 16px;
      min-height: 44px;
      vertical-align: middle;
      background: transparent;
      border: 2px solid #ffffff66;
      color: #ffffffcc;
      border-radius: 4px;
      width: 100%;
      resize: none; }
      .edit-section .es-body .es-input .es-option {
        color: #000;
        background-color: #eeeeee;
        font-size: 14px; }
    .edit-section .es-body .es-style-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      justify-content: space-between;
      width: 80px;
      height: 44px;
      padding: 0 4px;
      background: transparent;
      border: 2px solid #ffffff66;
      border-radius: 4px; }
      .edit-section .es-body .es-style-icons .ess-icon {
        display: inline-block;
        padding: 0.3rem 0.5rem;
        cursor: pointer; }
    .edit-section .es-body .es-color {
      width: 44px;
      height: 44px;
      align-self: center;
      border-radius: 4px;
      background: transparent;
      border: 2px solid #ffffff66;
      padding: 4px;
      cursor: pointer; }
    .edit-section .es-body .text-extras {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .edit-section .es-body .text-extras .es-color {
        margin-bottom: 6px; }
      .edit-section .es-body .text-extras .es-input {
        width: 44px; }
    .edit-section .es-body .es-wrapper {
      display: flex;
      flex-direction: row;
      position: relative; }
      .edit-section .es-body .es-wrapper:not(:last-child) {
        margin-bottom: 20px; }
        .edit-section .es-body .es-wrapper:not(:last-child) > *:not(:last-child) {
          margin-right: 6px; }
    .edit-section .es-body .es-column {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-bottom: 20px; }
    .edit-section .es-body .es-opacity-input {
      min-height: 0;
      height: 25px; }
    .edit-section .es-body .opacity-wrapper {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .edit-section .es-body .opacity-wrapper .opacity-range {
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px; }
      .edit-section .es-body .opacity-wrapper .es-input {
        width: 60px;
        text-align: center; }
    .edit-section .es-body .rotation-wrapper .es-input {
      width: 60px;
      text-align: center; }
    .edit-section .es-body .rotation-wrapper > *:not(:last-child) {
      margin-right: 6px; }
    .edit-section .es-body .value-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .edit-section .es-body .value-wrapper .es-input {
        width: 80px;
        text-align: center;
        margin-right: 6px; }
    .edit-section .es-body .es-value-editor {
      width: 200px; }
    .edit-section .es-body .waveform-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .edit-section .es-body .waveform-wrapper .es-input {
        text-align: center;
        margin-right: 6px; }
    .edit-section .es-body .es-preset-btn {
      background: #ffffff0c;
      border: 2px solid #ffffff66;
      color: #ffffffcc;
      padding: 6px 8px;
      font-size: 16px;
      border-radius: 4px;
      outline: none;
      min-height: 44px;
      min-width: 44px; }

.modal-close-button {
  position: absolute;
  font-size: 1rem;
  z-index: 1;
  top: 5px;
  right: 5px;
  opacity: 0.6;
  transition: 0.1s; }

.add-tracks-modal {
  background: #292929;
  position: relative;
  border-radius: 0 4px 4px 4px;
  padding: 4px;
  min-width: 500px;
  max-width: 500px;
  max-height: 99%;
  margin-right: 20px;
  margin-top: 4px;
  margin-left: -8px; }
  .add-tracks-modal input {
    font-size: 1rem; }

.add-tracks-modal .add-tracks-modal-body {
  height: 100%;
  width: 480px; }

.modal-close-button:hover {
  opacity: 1;
  cursor: pointer; }

.add-tracks-modal .nav-item .disabled {
  color: white;
  margin-top: 5px;
  transition: 0.1s; }

.add-tracks-modal-body .active {
  background: transparent; }

.add-tracks-modal .nav-item:hover .disabled {
  background: rgba(255, 255, 255, 0.2); }

.add-tracks-modal .nav-item .disabled span {
  margin-top: 6px;
  width: 100% !important;
  max-width: none; }

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  width: 35px;
  height: 35px; }

.ab-children {
  background-color: #292929;
  border: 1px solid #616161;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  z-index: 10001; }

.ab-active {
  color: #fff;
  border-color: #282828;
  background-color: #282828; }

.vp-control-bar .zoom-slider {
  height: 100px;
  width: 30px;
  padding: 0 !important;
  margin: 0 0 0 20px !important; }
  .vp-control-bar .zoom-slider input[type='range'] {
    width: 90px !important;
    -webkit-transform-origin: center left;
            transform-origin: center left;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.tb-switch .custom-control-label::before {
  background-color: transparent; }

.timeline-cursor-preview,
.timeline-ruler,
.timeline-ruler-header,
.timeline-ruler-border-bottom,
.timeline-ruler-marker,
.timeline-ruler-marker-ms,
.timeline-track,
.timeline-track-marker {
  border-color: #282828 !important; }

.timeline-current-track {
  background-color: #383838 !important; }

.timeline-track-section-keyframe-left,
.timeline-track-section-keyframe-right {
  background-color: #888 !important; }

.timeline-diamond-ruler-hidden,
.timeline-unselected-track {
  background-color: transparent !important; }

.timeline-track-section-keyframe-left,
.timeline-track-section-keyframe-right {
  background-color: transparent !important;
  border: 0px transparent !important; }

.timeline-ruler-cursor {
  border-top-color: #2a9fd6 !important; }

.timeline-track-cursor {
  border-left-color: #2a9fd6 !important; }

.timeline-ruler-cursor-preview {
  border-top-color: #888 !important; }

.timeline-track-cursor-preview {
  border-left-color: #888 !important; }

/* Fadeblack in/out animation */
@-webkit-keyframes kf-fadeblack-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
@keyframes kf-fadeblack-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.anim-fadeblack-out {
  opacity: 1;
  -webkit-animation-name: kf-fadeblack-out;
          animation-name: kf-fadeblack-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes kf-fadeblack-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes kf-fadeblack-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.anim-fadeblack-in {
  opacity: 1;
  -webkit-animation-name: kf-fadeblack-in;
          animation-name: kf-fadeblack-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

/* Fade in/out animation */
@-webkit-keyframes kf-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
@keyframes kf-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.anim-fade-out {
  opacity: 0;
  -webkit-animation-name: kf-fade-out;
          animation-name: kf-fade-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes kf-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes kf-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.anim-fade-in {
  opacity: 1;
  -webkit-animation-name: kf-fade-in;
          animation-name: kf-fade-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

/* Wipeleft in/out animation */
@-webkit-keyframes kf-wipeleft-in {
  from {
    -webkit-clip-path: inset(0 0 0 100%);
            clip-path: inset(0 0 0 100%); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }
@keyframes kf-wipeleft-in {
  from {
    -webkit-clip-path: inset(0 0 0 100%);
            clip-path: inset(0 0 0 100%); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }

.anim-wipeleft-in {
  -webkit-animation-name: kf-wipeleft-in;
          animation-name: kf-wipeleft-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes kf-wipeleft-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(0 0 0 100%);
            clip-path: inset(0 0 0 100%); } }

@keyframes kf-wipeleft-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(0 0 0 100%);
            clip-path: inset(0 0 0 100%); } }

.anim-wipeleft-out {
  -webkit-animation-name: kf-wipeleft-out;
          animation-name: kf-wipeleft-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

/* Wiperight in/out animation */
@-webkit-keyframes kf-wiperight-in {
  from {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }
@keyframes kf-wiperight-in {
  from {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }

.anim-wiperight-in {
  -webkit-animation-name: kf-wiperight-in;
          animation-name: kf-wiperight-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes kf-wiperight-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0); } }

@keyframes kf-wiperight-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0); } }

.anim-wiperight-out {
  -webkit-animation-name: kf-wiperight-out;
          animation-name: kf-wiperight-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

/* Wipedown in/out animation */
@-webkit-keyframes kf-wipedown-in {
  from {
    -webkit-clip-path: inset(0 0 100% 0);
            clip-path: inset(0 0 100% 0); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }
@keyframes kf-wipedown-in {
  from {
    -webkit-clip-path: inset(0 0 100% 0);
            clip-path: inset(0 0 100% 0); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }

.anim-wipedown-in {
  -webkit-animation-name: kf-wipedown-in;
          animation-name: kf-wipedown-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes kf-wipedown-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(0 0 100% 0);
            clip-path: inset(0 0 100% 0); } }

@keyframes kf-wipedown-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(0 0 100% 0);
            clip-path: inset(0 0 100% 0); } }

.anim-wipedown-out {
  -webkit-animation-name: kf-wipedown-out;
          animation-name: kf-wipedown-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

/* Wipeup in/out animation */
@-webkit-keyframes kf-wipeup-in {
  from {
    -webkit-clip-path: inset(100% 0 0 0);
            clip-path: inset(100% 0 0 0); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }
@keyframes kf-wipeup-in {
  from {
    -webkit-clip-path: inset(100% 0 0 0);
            clip-path: inset(100% 0 0 0); }
  to {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); } }

.anim-wipeup-in {
  -webkit-animation-name: kf-wipeup-in;
          animation-name: kf-wipeup-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

@-webkit-keyframes kf-wipeup-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(100% 0 0 0);
            clip-path: inset(100% 0 0 0); } }

@keyframes kf-wipeup-out {
  from {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0); }
  to {
    -webkit-clip-path: inset(100% 0 0 0);
            clip-path: inset(100% 0 0 0); } }

.anim-wipeup-out {
  -webkit-animation-name: kf-wipeup-out;
          animation-name: kf-wipeup-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

.background-screen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-size: cover !important;
  z-index: -10;
  opacity: 0.5; }

.login-page {
  font-size: 20px; }

h1 {
  position: relative;
  -webkit-filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.9));
          filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.9)); }

.homepage-logo {
  -webkit-filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.9));
          filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.9)); }

.new-project-modal > .modal-dialog {
  min-width: 500px; }

.res-page-return-button {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.1s; }

.res-page-return-button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9); }

.delete-project-structure-icon {
  cursor: pointer; }

.content {
  z-index: 0; }

#Homepage button {
  min-width: 380px;
  margin: 40px;
  margin-top: 80px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 25px;
  font-size: 2rem;
  transition: 0.1s;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.6);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4); }
  #Homepage button:hover {
    background: rgba(255, 255, 255, 0.4);
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1); }
  #Homepage button:active {
    -webkit-transform: scale3d(1.05, 1.05, 1);
            transform: scale3d(1.05, 1.05, 1); }
  #Homepage button:focus {
    outline: none !important; }
  #Homepage button svg {
    margin-right: 10px; }

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center; }

.btn-container {
  display: flex;
  justify-content: center; }

.info-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  padding: 10px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: 0.3s;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7); }
  .info-button svg {
    margin: 0; }
  .info-button span {
    opacity: 0;
    font-size: 0; }

.extended {
  width: 35vh;
  height: 35vh;
  border-radius: 5px; }
  .extended span {
    font-size: 1rem;
    opacity: 1;
    transition: 0.29s; }
  .extended svg {
    opacity: 0; }

.unauthorized-page {
  font-size: 20px; }

.build-status {
  display: flex;
  flex-direction: column;
  align-items: center; }

.build-status-action {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.resource-browser-modal .modal-body {
  min-height: 300px; }

.top-menu-bar .navbar-nav .nav-link {
  font-size: 1.1rem; }

.top-menu-bar .navbar-middle-button {
  padding: 3px 20px;
  font-size: 1rem;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.1s; }
  .top-menu-bar .navbar-middle-button:hover, .top-menu-bar .navbar-middle-button:active, .top-menu-bar .navbar-middle-button:focus {
    outline: none; }

.top-menu-bar .navbar-center {
  align-items: center; }

.top-menu-bar .dropdown-profile-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.8);
  border: none !important; }
  .top-menu-bar .dropdown-profile-link::after {
    display: none !important; }

.top-menu-bar .dropdown-profile-link:hover, .top-menu-bar .dropdown-profile-link:active {
  background: rgba(255, 255, 255, 0.2) !important; }

.top-menu-bar .dropdown-item {
  padding: 0; }

.top-menu-bar .btn {
  border: none; }
  .top-menu-bar .btn svg {
    width: 1.25em;
    height: 1.25em; }

.save-as-dropdown {
  display: flex;
  width: 460px;
  justify-content: center;
  padding: 22px 0;
  align-items: center; }
  .save-as-dropdown .sa-text {
    margin-right: 4px;
    color: #fffffff1; }
  .save-as-dropdown .sa-input {
    font-size: 12px;
    vertical-align: middle;
    background: transparent;
    border: 1px solid #ffffff66;
    color: #fffffff1;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 14px;
    resize: none;
    min-width: 240px;
    outline: none; }
  .save-as-dropdown .sa-button {
    font-size: 12px;
    outline: none;
    text-align: center;
    width: 54px;
    padding: 4px;
    border-radius: 5px;
    color: #fffffff1;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff66; }
    .save-as-dropdown .sa-button:not(:last-child) {
      margin-right: 4px; }

.export-options-menu {
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer; }
  .export-options-menu .export-option {
    display: flex;
    align-items: baseline;
    transition: 0.2s; }
    .export-options-menu .export-option .option-link {
      white-space: nowrap; }
  .export-options-menu .export-option:hover {
    background: rgba(255, 255, 255, 0.3); }

