.volume-monitor {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .name {
    margin: 1px;
    font-size: 9px;
    color: #2dff34;
    background-color: #000000;
    text-align: center;
    font-weight: 500;
  }

  .volume-meter-container {
    display: flex;
    height: 100%;
  }
}
