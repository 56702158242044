.add-personal-file-progress {
  .upload-progress-title {
    text-align: center;
    margin-bottom: 14px;
    font-size: 20px;
  }

  .upload-progress-filename {
    text-align: center;
    margin-bottom: 5px;
    font-size: 16px;
  }
}
