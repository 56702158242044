@import '../../styles/themes';

.thumbnail-card {
  position: relative;
  border: 3px solid transparent;
  &.thumbnail-card-selected {
    border-color: $color-primary;
  }

  .thumbnail-card-name {
    text-overflow: ellipsis;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 2px;
    font-size: 12px;
  }

  .thumbnail-card-options-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
