.transition-preview-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .transition-preview-block {
    display: flex;
    flex-direction: column;

    .transition-preview {
      border-radius: 2px;
      cursor: pointer;
      max-width: 200px;
      height: auto;
    }

    .transition-preview.selected {
      outline: 2px solid rgb(30, 144, 255);
    }

    .transition-video {
      position: absolute;
    }
  }
}
.es-title {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 5px;
}
.duration-wrapper {
  display: flex;

  .es-duration-input {
    max-width: 200px;
    margin-top: 0;
    padding: 0 5px;
  }
}
