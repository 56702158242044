.video-player-loading {
  position: absolute !important;
  justify-content: center !important;
  display: flex !important;
  color: #ced4da;
  margin: 0;
  top: 50%;
  z-index: 10;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
