.core-typehead {
  @extend .position-relative;
}

.core-typehead-input {
  @extend .form-control;
}

.core-typehead-suggestions {
  @extend .position-absolute;
  @extend .w-100;
  @extend .overflow-auto;
  max-height: 300px;
}

.core-typehead-suggestions-item {
  @extend .p-1;
}
