.rc-progress {
  margin: 5px 0;
  .rc-progress-percent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: black;
    font-size: 10px;
    font-weight: 500;
    z-index: 1;
  }

  .progress {
    position: relative;
  }
  .rc-progress-text {
    text-align: center;
    width: 100%;
    color: black;
    font-size: 10px;
  }
}
