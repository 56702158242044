@import 'themes';

/* Scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
}

/* Scrollbar for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: $scrollbar-track-color;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color;
  border: none;
}

.btn:focus,
.btn.mousedown:focus,
.form-control,
.form-control:focus {
  box-shadow: none;
}

.form-control,
.form-control:focus {
  border: 1px solid $border-color;
  background-color: $border-color;
  color: rgb(173, 175, 174);
}

.form-control:focus {
  border-color: $color-primary;
}

.form-control:disabled,
.form-control[readonly] {
  color: $text-color;
  background-color: transparent;
}

.input-group-text {
  color: $text-color;
}

.btn-outline-primary {
  color: $text-color;
  border-color: $border-color;
  &:hover {
    border-color: $border-color;
    background-color: $border-color;
  }
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $border-color;
  background-color: transparent;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

.navbar.bg-dark {
  background-color: $background-color !important;
}

.border {
  border: 1px solid $border-color !important;
}

.border-top {
  border-top: 1px solid $border-color !important;
}

.border-right {
  border-right: 1px solid $border-color !important;
}

.border-bottom {
  border-bottom: 1px solid $border-color !important;
}

.border-left {
  border-left: 1px solid $border-color !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $text-color !important;
  background-color: $background-color !important;
  border-color: $border-color !important;
  box-shadow: none !important;
  &:hover {
    background-color: $border-color !important;
  }
}

.dropdown-menu {
  left: none !important;
  right: 20px !important;
}
.dropdown-item {
  color: rgba(255, 255, 255, 0.8) !important;
  transition: 0.2s;
}
.dropdown-item a,
.dropdown-item p {
  width: 100% !important;
  font-size: 1.1rem;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.8) !important;
}
.dropdown-item a:hover {
  text-decoration: none;
}
.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.3) !important;
}
.dropdown-item svg {
  margin-right: 10px !important;
}

.modal-content {
  background-color: $background-color !important;
}

.modal-backdrop {
  background-color: $backdrop-color !important;
}
.toast {
  background-color: $timeline-current-track-background !important;
}

.toast-header {
  background-color: $timeline-current-track-background !important;
  color: $text-color !important;
}

.no-rounded {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.rc-progress {
  .progress {
    background-color: rgb(146, 146, 146);
  }

  .rc-progress-percent {
    color: rgb(255, 255, 255);
  }

  .rc-progress-text {
    color: rgb(255, 255, 255);
  }
}

.bg-info {
  background-color: $color-primary !important;
}
