.video-player {
  .player-bg {
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 150px;
    color: lightgray;
  }
  .player-tracks {
    z-index: 2;
  }
}
