@import "./screen-sizes.scss";

.min-height-0 {
  min-height: 0;
}

.min-width-0 {
  min-width: 0;
}

.unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}
