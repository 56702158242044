@import '../../styles/themes';

.timeline-cursor-preview,
.timeline-ruler,
.timeline-ruler-header,
.timeline-ruler-border-bottom,
.timeline-ruler-marker,
.timeline-ruler-marker-ms,
.timeline-track,
.timeline-track-marker {
  border-color: $border-color !important;
}

.timeline-current-track {
  background-color: $timeline-current-track-background !important;
}

.timeline-track-section-keyframe-left,
.timeline-track-section-keyframe-right {
  background-color: $text-color !important;
}

.timeline-diamond-ruler-hidden,
.timeline-unselected-track {
  background-color: transparent !important;
}

.timeline-track-section-keyframe-left,
.timeline-track-section-keyframe-right {
  background-color: transparent !important;
  border: 0px transparent !important;
}

.timeline-ruler-cursor {
  border-top-color: $color-primary !important;
}

.timeline-track-cursor {
  border-left-color: $color-primary !important;
}

.timeline-ruler-cursor-preview {
  border-top-color: $text-color !important;
}

.timeline-track-cursor-preview {
  border-left-color: $text-color !important;
}
