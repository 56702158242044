@import '../../styles/themes';

.resource-browser {
  height: 100%;
  .dropdown {
    .dropdown-item {
      padding: 0.25rem 0.75rem;
    }
  }
}
