.times-box {
  display: flex;
  align-items: center;

  .tb-switch {
    display: flex;
    margin-right: 8px;
    font-size: 12px;
    align-items: center;

    .tb-switch-ts {
      margin-right: 4px;
    }
    .tb-switch-ms {
      margin-left: -4px;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      cursor: pointer;
    }
  }

  .tb-times {
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}
