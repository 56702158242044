.top-menu-bar {
  .navbar-nav .nav-link {
    font-size: 1.1rem;
  }

  .navbar-middle-button {
    padding: 3px 20px;
    font-size: 1rem;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: 0.1s;

    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }

  .navbar-center {
    align-items: center;
  }

  // dropdown navbar

  .dropdown-profile-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 10px;
    transition: 0.3s;
    color: rgba(255, 255, 255, 0.8);
    border: none !important;
    &::after {
      display: none !important;
    }
  }

  .dropdown-profile-link {
    &:hover,
    &:active {
      background: rgba(255, 255, 255, 0.2) !important;
    }
  }
  .dropdown-item {
    padding: 0;
  }

  .btn {
    border: none;
    svg {
      width: 1.25em;
      height: 1.25em;
    }
  }
}
